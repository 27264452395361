import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React from "react";
import { getJobsData, makePayment } from "../../features/jobs/jobs";
import { useDispatch, useSelector } from "react-redux";

function PayVendor({ data, closeModal, refresh }) {
  const dispatch = useDispatch(),
    { modal } = useSelector(getJobsData);

  const makePayments = async (e) => {
    e.preventDefault();
    const res = await dispatch(
      makePayment({ isBulk: false, vendorId: data.VendorID })
    ).unwrap();
    res?.success && refresh?.();
  };

  return (
    <Box component="form" noValidate onSubmit={makePayments}>
      <div className="flex justify-between w-full">
        <p className="font-[600]">Account Name</p>
        <p>{data?.VendorPreferredName || data?.VendorName || "N/A"}</p>
      </div>
      <br />

      {/* <div className="flex justify-between w-full">
        <p className="font-[600]">Account Number</p>
        <p>{data?.VendorAcctNo || "N/A"}</p>
      </div>
      <br /> */}

      <div className="flex justify-end capitalize">
        <div className="inline-block">
          <Button
            onClick={closeModal}
            variant="contained"
            color="error"
            className="capitalize mr-5"
            style={{ textTransform: "capitalize", margin: "2em" }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            {...{ loading: modal.loader.confirm }}
            variant="text"
            sx={{
              height: "2.3rem",
              alignItems: "center",
              px: "1em",
              fontSize: "semibold",
              color: "red",
              background: "#f5ebeb",
              textTransform: "capitalize",
            }}
          >
            {" "}
            Confirm{" "}
          </LoadingButton>
        </div>
      </div>
    </Box>
  );
}

export default PayVendor;
