/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import HomeLayout from "../../layouts/Home";
import { Typography } from "@material-tailwind/react";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ICopy } from "../../utils/icons.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  fetchData,
  getDownloadData,
  getJobsData,
  openModal,
  setCurrentJob,
  setPagination,
  closeJob,
  confirmJob,
  rejectJob,
} from "../../features/jobs/jobs";
import CustomModal from "../../components/modals/Index";
import ViewJob from "./ViewJob";
import VendorAssignment from "../manualReassignment/VendorAssignment";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { getDefaultState, setPrintMod } from "../../features/defaultSlice";
import PrintButton from "../../components/others/Print";
import { useLocation } from "react-router-dom";
import { getAuthData, setModal } from "../../features/auth/authSlice";
import { Button, Tooltip } from "@mui/material";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import RecyclingIcon from "@mui/icons-material/Recycling";
import CurtainsClosedIcon from "@mui/icons-material/CurtainsClosed";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ConfirmationModal from "../../components/modals/confirmationModal";
import dayjs from "dayjs";
import { clearVendorData } from "../../features/vendor/vendor";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import MoveJobs from "../../components/modals/MoveJobs";

const PendingAcknowledgment = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    [showText, setShowText] = useState(true),
    [checkboxData, setcheckboxData] = useState([]),
    [adminAction, setAdminAction] = useState({}),
    { appType, ...authData } = useSelector(getAuthData),
    { pathname } = useLocation(),
    {
      modal,
      loading,
      pagination,
      downloadUrl,
      currentJob,
      downloadData,
      ...jobsData
    } = useSelector(getJobsData),
    { printMod } = useSelector(getDefaultState),
    pendingAcknowledgedJob = jobsData[camelCasedPathName()];

  useEffect(() => {
    dispatch(clearVendorData());
    dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Pending Acknowledgement" }));
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  let data = pendingAcknowledgedJob?.map((d) => {
    let model = {};

    if (AppType.cs === appType) model["CS ID"] = d?.AcctRefID;
    else model["CAV ID"] = d?.AcctRefID;

    if (getRole() !== Roles.vendor) {
      model["Account Number"] = d?.AcctNumber || "N/A";
    }
    model["Account Name"] = capitalize(d?.AcctName) || "N/A";

    if (AppType.cs === appType) {
      model["RC Number"] = d?.RCNumber || "N/A";

      model["Date of Incorporation"] = d?.DateOfIncorporation
        ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
        : "N/A";
    } else {
      model["Address"] = capitalize(d?.AcctAddress) || "N/A";
      model["Updated Address"] = capitalize(d?.UpdatedAcctAddress) || "N/A";
    }

    model = {
      ...model,
      State: capitalize(d?.State?.Name || "N/A"),
      "Vendor's Name":
        d?.AssignedVendor?.VendorPreferredName ||
        d?.AssignedVendor?.VendorName ||
        "N/A",
      ...(getRole() !== Roles.vendor && {
        "Assigned By": d?.InitiatedByUser?.firstName
          ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
          : "N/A",
      }),
      "Assigned Date": d?.DateAssigned
        ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
        : "N/A",
      "Completed Date": d?.DateConfirmed
        ? dayjs(d?.DateConfirmed).format("MMM DD, YYYY")
        : "N/A",
      Status: d?.AddedInfo || "N/A",
      _data: d,
    };
    return model;
  });

  const dropdownData = {
    visible: true,
    type: "icon",
    icon: <VisibilityIcon />,
    action: (_, state) => {
      dispatch(openModal("open"));
      dispatch(setCurrentJob(state?._data));
    },
  };

  const refresh = async () => {
    dispatch(closeModal());
    setcheckboxData([]);
    await dispatch(fetchData());
  };

  const buttonStyle = useCallback(
    (i) => ({
      borderRadius: 0,
      minWidth: "50px",
      border: `${showText ? "0px" : "1px"} solid red`,
      borderRight:
        i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid red`,
      textTransform: "capitalize",
      fontSize: "26px",
      "&:hover": { backgroundColor: "#D70900", color: "white" },
      ".MuiLoadingButton-loadingIndicatorCenter": {
        color: "var(--C_primary) !important",
      },
      "&:disabled": {
        border: `${showText ? "0px" : "1px"} solid grey`,
        borderRight:
          i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid grey`,
        textTransform: "capitalize",
      },
    }),
    [showText]
  );

  const checkboxAction = {
    setValues: (data) => setcheckboxData(data),
    values: checkboxData,
  };

  const initBulkAction = (meta, idx) => {
    if (idx) {
      setAdminAction(meta);
      dispatch(openModal("close"));
    } else meta.action();
  };

  const buttonMeta = [
    {
      title: "Reassign",
      Icon: RecyclingIcon,
      action: (_) => dispatch(openModal()),
    },
    {
      title: "Close",
      Icon: CurtainsClosedIcon,
      action: closeJob,
      loading: modal.loader.closedJobs,
    },
    {
      title: "Confirm",
      Icon: ThumbUpAltIcon,
      action: confirmJob,
      loading: modal.loader.confirm,
    },
    {
      title: "Reject",
      Icon: ThumbDownIcon,
      action: rejectJob,
      loading: modal.loader.reject,
    },
    // {
    //   title: "Move",
    //   Icon: DriveFileMoveIcon,
    //   action: rejectJob,
    //   loading: modal.loader.reject,
    // },
  ];

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Pending Acknowledgment
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Accounts Verified by vendors, but awaiting confirmation.
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>

      <div>
        {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
          <div className="flex justify-between mt-5 flex-wrap gap-y-5">
            <div>
              {buttonMeta.map((meta, i) => (
                <Tooltip
                  key={i}
                  className="py-0 px-0"
                  title={meta.title + " Jobs"}
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={(_) => initBulkAction(meta, i)}
                      color="error"
                      disabled={checkboxData.length ? false : true}
                      sx={(_) => buttonStyle(i, showText)}
                    >
                      <meta.Icon />{" "}
                      {showText && (
                        <span className="text-[15px] ml-1"> {meta.title} </span>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              ))}

              <Tooltip
                className="py-0 px-0"
                title={showText ? "Collapse" : "Expand"}
                placement="top"
              >
                <Button
                  color="error"
                  onClick={(_) => setShowText(!showText)}
                  sx={{ minWidth: "20px", borderRadius: "30px", ml: "2em" }}
                  className="ml-5"
                >
                  {showText ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
                </Button>
              </Tooltip>
            </div>

            <button
              onClick={(_) => {
                dispatch(setModal({ close: true }));
              }}
              disabled={checkboxData.length ? false : true}
              className={`px-2 py-1 hover:border-[#D70900] shadow disabled:text-grey disabled:bg-transparent disabled:border-grey flex items-center rounded border hover:bg-[#D70900] text-[#D70900] hover:text-white`}
            >
              <DriveFileMoveIcon />{" "}
              <span className="text-[15px] ml-1">Move Jobs</span>
            </button>
          </div>
        )}
        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            data,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            action: getRole() === Roles.bank && dropdownData,
            tableMsg: ["No Pending Acknowledgement"],
          }}
        />
      </div>

      {getRole() === Roles.bank && (
        <>
          <CustomModal
            {...{
              title: "View & Action form",
              close: (_) => dispatch(closeModal("open")),
              open: modal.open,
            }}
          >
            <ViewJob
              {...{
                refresh,
                data: currentJob,
                appType,
                openVendorAssignModal: (_) => {
                  dispatch(closeModal("open"));
                  dispatch(openModal("close"));
                },
              }}
            />
          </CustomModal>

          <CustomModal
            {...{
              title: "Vendor Assign Form",
              close: (_) => dispatch(closeModal("close")),
              open: modal.close,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(closeModal("close")),
                data: currentJob,
                refresh: (_) => {
                  dispatch(closeModal("close"));
                  dispatch(fetchData());
                },
              }}
            />
          </CustomModal>
        </>
      )}

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(closeModal()),
              open: modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(closeModal()),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>
          <CustomModal
            {...{
              title: `Move ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              }`,
              open: authData.modal.close,
              close: (_) => dispatch(setModal({ close: false })),
            }}
          >
            <MoveJobs
              {...{
                closeModal: (_) => dispatch(setModal({ close: false })),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: modal.close,
              close: (_) => dispatch(closeModal("close")),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading:
                adminAction.title === "Close"
                  ? modal.loader.closedJobs
                  : adminAction.title === "Confirm"
                  ? modal.loader.confirm
                  : modal.loader.reject,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default PendingAcknowledgment;
