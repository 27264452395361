/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AreaChart from "../../components/others/AreaChart";
import DateFilterButton from "../../components/others/DateFilterButton";
import PieChart from "../../components/others/PieChart";
import { getAuthData } from "../../features/auth/authSlice";
import {
  getDashboardData,
  setFilter,
} from "../../features/dashboard/dashboard";
import HomeLayout from "../../layouts/Home";
import {
  JobsColorData,
  registeredVendorsColorData,
} from "../../utils/fakeData";
import { AppType, getAmount } from "../../utils/helper";
import { IExport } from "../../utils/icons.utils";

const AdminDash = () => {
  const dispatch = useDispatch(),
    { preloading, appType } = useSelector(getAuthData),
    { filter, stats, loading } = useSelector(getDashboardData);

  const handleDateFilter = (month) => {
    dispatch(setFilter(month));
  };

  const data = useMemo(() => {
    return {
      totalCountOfPaidJobs: stats?.totalCountOfPaidJobs,
      totalJobsAwaitingPayment: stats?.totalJobsAwaitingPayment,
      lockedVendors: stats?.lockedVendors || 0,
      registeredVendorsData: [
        {
          value: stats?.percentageExcellentSlaCompliance || 0,
          name: `${
            stats?.percentageExcellentSlaCompliance || 0
          }% SLA Compliance`,
        },
        {
          value: stats?.percentageGoodSlaCompliance || 0,
          name: `${stats?.percentageGoodSlaCompliance || 0}% SLA Compliance`,
        },
        {
          value: stats?.percentagePoorSlaCompliance || 0,
          name: `${stats?.percentagePoorSlaCompliance || 0}% SLA Compliance`,
        },
      ],
      totalRegisteredVendors: stats?.totalRegisteredVendors,
      totalRegisteredSolicitors: stats?.totalRegisteredSolicitors,
      filter: stats?.registeredEmployees?.filter,
      rejectedJobs: stats?.rejectedJobs,
      closedJobs: stats?.closedJobs,
    };
  }, [loading, stats, filter, preloading]);

  const JobsChartData = useMemo(() => {
    return [
      {
        value: data?.closedJobs || 0,
        name: loading ? "loading..." : `${data?.closedJobs || 0} Closed Jobs`,
      },
      {
        value: data?.rejectedJobs || 0,
        name: loading
          ? "loading..."
          : `${data?.rejectedJobs || 0} Rejected Jobs`,
      },
    ];
  }, [loading, stats, preloading]);

  const paidJobsData = useMemo(() => {
    return [
      {
        value: data?.totalCountOfPaidJobs || 0,
        name: loading
          ? "loading..."
          : `${data?.totalCountOfPaidJobs || 0} Paid Jobs`,
      },
      {
        value: data?.totalJobsAwaitingPayment || 0,
        name: loading
          ? "loading..."
          : `${data?.totalJobsAwaitingPayment || 0} Awaiting Payment`,
      },
    ];
  }, [loading, stats, preloading]);

  const activeAndInactiveData = useMemo(() => {
    const count =
      appType === AppType.cav
        ? data?.totalRegisteredVendors || 0
        : data?.totalRegisteredSolicitors || 0;

    return [
      {
        value: getAmount(count - data?.lockedVendors),
        name: loading
          ? "loading..."
          : `${getAmount(count - data?.lockedVendors) || 0} Active ${
              appType === AppType.cav ? "Vendors" : "Solicitors"
            }`,
      },
      {
        value: data?.lockedVendors || 0,
        name: loading
          ? "loading..."
          : `${data?.lockedVendors || 0} Inactive ${
              appType === AppType.cav ? "Vendors" : "Solicitors"
            }`,
      },
    ];
  }, [loading, stats, preloading]);

  return (
    <HomeLayout>
      <div className="">
        <Typography className="text-[24px] font-[500]">Dashboard</Typography>
        <Typography className="text-[18px] font-[400]">Overview</Typography>
      </div>
      <div className="lg:grid lg:grid-cols-4 gap-4 my-8">
        <div className="lg:my-0 my-6 w-full xl:h-full lg:h-full xl:w-full xl:my-0 px-5 xl:col-span-1 lg:col-span-2 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
          <h2 className="text-[17px] font-[600] tracking-[0.18px]">
            Registered {appType === AppType.cav ? "Vendors" : "Solicitors"}
          </h2>
          <h5 className="text-[22px] font-[700] mt-4">
            {loading
              ? "..."
              : getAmount(
                  appType === AppType.cav
                    ? data?.totalRegisteredVendors || 0
                    : data?.totalRegisteredSolicitors || 0
                )}
          </h5>
          <div className="w-full">
            <PieChart
              id="adminOne"
              wrapperClass="overflow-x-hidden md:pl-16 lg:pl-10 xl:pl-0"
              pieChartClass="xl:w-[470px] lg:w-[430px] md:w-[580px] w-full h-[470px] md:h-[270px]  lg:h-[120px] xl:h-[150px]"
              data={data?.registeredVendorsData}
              color={registeredVendorsColorData}
            />
          </div>
        </div>

        <div className="lg:my-0 my-6 xl:my-0 px-5 w-full xl:col-span-1 lg:col-span-2 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
          <h2 className="text-[17px] font-[600] tracking-[0.18px]">
            Active & Inactive{" "}
            {appType === AppType.cav ? "Vendors" : "Solicitors"}
          </h2>
          <h5 className="text-[2px] font-[700] mt-4">
            {/* {loading ? "..." : getAmount(stats?.totalJobs || 0)} */}
          </h5>
          <div className="w-full">
            <PieChart
              id="activeAndInactiveData"
              data={activeAndInactiveData}
              color={["green", "red"]}
              wrapperClass=" md:pl-16 lg:pl-0 xl:pl-0"
              pieChartClass="xl:w-[470px] lg:w-[430px] md:w-[580px] w-full h-[470px] md:h-[270px]  lg:h-[120px] xl:h-[150px]"
            />
          </div>
        </div>

        <div className="lg:my-0 my-6 xl:my-0 px-5 w-full xl:col-span-1 lg:col-span-2 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
          <h2 className="text-[17px] font-[600] tracking-[0.18px]">
            Rejected & Closed Jobs
          </h2>
          <h5 className="text-[2px] font-[700] mt-4">
            {/* {loading ? "..." : getAmount(stats?.totalJobs || 0)} */}
          </h5>
          <div className="w-full">
            <PieChart
              id="bankDash"
              data={JobsChartData}
              color={JobsColorData}
              wrapperClass=" md:pl-16 lg:pl-0 xl:pl-0"
              pieChartClass="xl:w-[470px] lg:w-[430px] md:w-[580px] w-full h-[470px] md:h-[270px]  lg:h-[120px] xl:h-[150px]"
            />
          </div>
        </div>
        <div className="lg:my-0 my-6 xl:my-0 px-5 w-full xl:col-span-1 lg:col-span-2 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
          <h2 className="text-[17px] font-[600] tracking-[0.18px]">
            Paid Jobs & Awaiting Payment
          </h2>
          <h5 className="text-[2px] font-[700] mt-4">
            {/* {loading ? "..." : getAmount(stats?.totalJobs || 0)} */}
          </h5>
          <div className="w-full">
            <PieChart
              id="paidJobsData"
              data={paidJobsData}
              color={["green", "orange"]}
              wrapperClass=" md:pl-16 lg:pl-0 xl:pl-0"
              pieChartClass="xl:w-[470px] lg:w-[430px] md:w-[580px] w-full h-[470px] md:h-[270px]  lg:h-[120px] xl:h-[150px]"
            />
          </div>
        </div>
      </div>
      <div className="relative  my-6 w-full h-full xl:col-span-1 lg:col-span-2 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
        <div className="grid xl:grid-cols-3 ">
          <h2 className="mx-5 text-[20px] font-[600] tracking-[0.18px] xl:col-span-1">
            Registered Employee
          </h2>
          <div className="grid md:grid-cols-3 lg:grid-cols-2  xl:col-span-2 lg:col-span-3">
            <div className="md:flex lg:gap-8 md:col-span-2 lg:col-span-1 md:ml-5 xl:ml-0">
              <DateFilterButton
                text="12 Months"
                active={filter === "12_MONTHS"}
                onClick={() => handleDateFilter("12_MONTHS")}
              />
              <DateFilterButton
                text="6 Months"
                active={filter === "6_MONTHS"}
                onClick={() => handleDateFilter("6_MONTHS")}
              />
              <DateFilterButton
                text="30 Days"
                active={filter === "30_DAYS"}
                onClick={() => handleDateFilter("30_DAYS")}
              />
              <DateFilterButton
                text="7 Days"
                active={filter === "7_DAYS"}
                onClick={() => handleDateFilter("7_DAYS")}
              />
            </div>
            <div className="hidden md:justify-end justify-center md:px-14">
              <button
                type="button"
                className="inline-flex items-center lg:w-[35%]  w-full border border-gray rounded-md justify-center py-2 text-sm font-semibold text-[#18181B] "
              >
                <img
                  src={IExport}
                  alt="IExport"
                  className="mr-1 mb-1"
                  aria-hidden="true"
                />
                Export PDF
              </button>
            </div>
          </div>
        </div>
        <div className="w-full relative ">
          <AreaChart
            id="employeeChart"
            data={stats?.registeredEmployees?.data?.map(
              ({ totalCreated }) => totalCreated
            )}
            showXAxis={true}
            wrapperClass={"w-full"}
            areaChatClass={"w-full"}
            smooth={false}
            name="employeeChart"
            colors={{
              line: "#FF0000",
              areaStart: "#FF1111",
              areaEnd: "#FFDADA",
            }}
            xAxisData={stats?.registeredEmployees?.data?.map(
              ({ date }) => date
            )}
          />
        </div>
      </div>
    </HomeLayout>
  );
};

export default AdminDash;
