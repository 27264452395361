import React from 'react'

const DateFilterButton = ({ text, active, onClick }) => (
    <button
      onClick={onClick}
      className={`px-3 w-full py-1 text-sm rounded-lg ${
        active ? 'border border-gray-300 ' : ''
      }`}
    >
      {text}
    </button>
  );

export default DateFilterButton