/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  // Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "../../components/others/OtpInput";
import {
  // AzureOAuth,
  asyncLogin,
  getAuthData,
  submitToken,
} from "../../features/auth/authSlice";
import Alert from "../../utils/alert";
import config from "../../utils/config";
import { AppType, Roles } from "../../utils/helper";
import { userLogin } from "../../utils/validators";
// import MsalAuthButton from "../../components/others/AzureLogin";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../../utils/azureAuthConfig";

const Login = () => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { payload, loading } = useSelector(getAuthData);

  const formik = useFormik({
    initialValues: payload,
    validationSchema: userLogin,
    onSubmit: async (values) => {
      try {
        const res = await dispatch(
          asyncLogin({
            ...values,
            password: AES.encrypt(
              values.password,
              config.encryptionKey
            ).toString(),
            email: values?.email?.trim(),
          })
        ).unwrap();
        if (res?.success === true) {
          if (
            (res?.data?.vendorType === Roles.vendor &&
              config.appType === AppType.cs) ||
            (res?.data?.vendorType === Roles.solicitor &&
              config.appType === AppType.cav)
          ) {
            Alert({
              type: "error",
              message: `You don't have access to this portal. Please visit the ${
                res?.data?.vendorType === Roles.vendor
                  ? AppType.cav
                  : AppType.cs
              } portal to login`,
              timer: 10000,
            });
          } else setMove(true);
        }
      } catch (err) {
        console.error(err);
        Alert({ type: "error", message: "Something went wrong" });
      }
    },
  });
  const [move, setMove] = useState(false);
  /*const history = () => {};
  const { instance, accounts, inProgress } = useMsal();
  const accessTokenRequest = { ...loginRequest, account: accounts[0] };

  useEffect(() => {
    if (inProgress) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          const loginvalues = {
            email: accessTokenResponse.account.username,
            msalData: JSON.stringify(accessTokenResponse),
          };
          // Storage.set("user-email", loginvalues.email);
          // Storage.set("email-value", loginvalues.email);
          AzureOAuth(loginvalues, history);
        })
        .catch((e) => console.log(e.message));
    }
  }, []);*/

  return (
    <div className="bg-image h-screen overflow-x-hidden my-0 py-0">
      <Box className="flex flex-col  items-between gap-10 justify-start px-10">
        <div className="flex justify-end">
          <img
            alt="brand"
            src="/logo.svg"
            className="w-[150px] py-1 my-5 rounded h-[50px]"
          />
        </div>
        <div className="flex items-center h-full">
          <Box className="self-center w-full">
            <div className="flex xl:justify-between justify-center flex-wrap items-center w-full mb-">
              <div className="text-white">
                <h1 className="mb-0 pb-0 lg:text-[40px] sm:text-[35px] text-[25px] font-bold leading-0">
                  {config.appType && config?.appType?.split?.(" ")?.[0]}{" "}
                  {config.appType && config?.appType?.split(" ")?.[1]}{" "}
                </h1>
                {config.appType === AppType.cav && (
                  <h1 className="mt-0 pt-0 font-bold lg:text-[40px] sm:text-[35px] text-[25px]">
                    {" "}
                    Verification
                  </h1>
                )}
                <br />
              </div>

              <Box className="bg-[rgba(255,255,255,.9)] md:mb-10 mb-5 px-[1.5em] py-10 rounded w-[500px]">
                <div className="w-full relative h-full">
                  <Box
                    noValidate
                    component="form"
                    onSubmit={formik.handleSubmit}
                    className={`w-full fadeLeft relative ${
                      move ? "z-0" : "z-40"
                    }`}
                    style={{ left: move && "-30%", opacity: move ? 0 : 1 }}
                  >
                    <h1 className="text-3xl ">Login</h1>
                    <Typography className="text-[400] text-[16px] text-[#52525B]">
                      Input username and password.
                    </Typography>

                    <div className="mt-5">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-[16px] font-medium  text-[#090914]"
                      >
                        Username
                      </label>
                      <TextField
                        value={formik.values.email}
                        type="email"
                        name="email"
                        id="email"
                        sx={{ ".MuiInputBase-root": { borderRadius: "10px" } }}
                        className="block w-full bg-[#F8FAFC]  border-0 rounded-[10px] py-4 pl-5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-3 sm:text-sm sm:leading-6"
                        placeholder="E.g johndoe@hotmail.com"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={formik.handleFocus}
                        error={formik.errors.email && formik.touched.email}
                        helperText={
                          formik.errors.email && formik.touched.email
                            ? formik.errors.email
                            : ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-[16px] font-medium  text-[#090914]"
                      >
                        Password
                      </label>
                      <TextField
                        sx={{ ".MuiInputBase-root": { borderRadius: "10px" } }}
                        value={formik.values.password}
                        type="password"
                        name="password"
                        id="password"
                        className="block w-full bg-[#F8FAFC] border-0 rounded-[10px] py-4 pl-5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-3  sm:text-sm sm:leading-6"
                        placeholder="*****"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={formik.handleFocus}
                        error={
                          formik.errors.password && formik.touched.password
                        }
                        helperText={
                          formik.errors.password && formik.touched.password
                            ? formik.errors.password
                            : ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="flex justify-end mt-2">
                      <Link to={config.routes.forgotPassword}>
                        Forgot password?
                      </Link>
                    </div>

                    <LoadingButton
                      variant="contained"
                      color="error"
                      fullWidth
                      {...{
                        loading,
                        sx: {
                          textTransform: "capitalize",
                          my: "20px",
                          bgcolor: "#D70900",
                          borderRadius: "10px",
                          fontSize: "18px",
                        },
                      }}
                      type="submit"
                      className="my-5"
                    >
                      Submit
                    </LoadingButton>

                    {/* <Divider>OR</Divider>

                    <MsalAuthButton login={() => {}} history={history} /> */}
                  </Box>
                  <div
                    className={`fadeLeft absolute top-0 w-full ${
                      move ? "opacity-1 z-40" : "opacity-0 z-0"
                    }`}
                    style={{ left: !move && "30%" }}
                  >
                    <Box className="text-[#201600] flex">
                      <span
                        className="flex cursor-pointer items-center"
                        onClick={(_) => {
                          formik.setValues({ ...formik.values, token: "" });
                          setMove(false);
                        }}
                      >
                        <ArrowBackIosNewIcon color="#201600" fontSize="small" />
                        <Typography className="text-[#201600] text-[16px] ">
                          Back
                        </Typography>
                      </span>
                    </Box>

                    <div className="mt-5 mb-20">
                      <Typography className="text-[400] text-[16px] text-[#52525B]">
                        Enter the Token sent to your mailbox to continue.
                      </Typography>
                    </div>

                    <OtpInput
                      {...{
                        value: formik.values.token,
                        length: 8,
                        disabled: loading,
                        onSubmit: async (entrustToken) => {
                          const res = await dispatch(
                            submitToken({
                              entrustToken,
                              email: formik.values.email,
                            })
                          ).unwrap();
                          res?.success &&
                            Alert({
                              type: "success",
                              message: res?.message,
                              timer: 1000,
                              cb: () => {
                                const decode = JSON.parse(
                                  AES.decrypt(
                                    res?.data?.data,
                                    config.encryptionKey
                                  ).toString(UTF8)
                                );
                                decode.token = res?.data?.token;

                                decode?.isNewUser
                                  ? navigate(config.routes.changePassword, {
                                      state: decode,
                                    })
                                  : (window.location.href =
                                      config.routes.dashboard);
                              },
                            });
                        },
                      }}
                    />
                  </div>
                </div>
              </Box>
            </div>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Login;
