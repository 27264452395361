import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";
import BACKEND from "../../utils/backend";
import config from "../../utils/config";
import { AppType, loggedInUser } from "../../utils/helper";
import Storage from "../../utils/storage";

export const submitToken = createAsyncThunk(
  "/auth/submitToken",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEmail(payload.email));
      thunkAPI.dispatch(setOtp(payload.entrustToken));
      return new BACKEND().send({
        type: "post",
        to: "/auth/admin-banker/login",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const changePassword = createAsyncThunk(
  "/auth/changePassword",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEmail(payload.email));
      return new BACKEND().send({
        type: "post",
        to: "/user/profile/change-password",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/auth/resetPassword",
  async (payload, thunkAPI) => {
    let body = {};
    body.email = payload.email;
    if (payload.password) body.password = payload.password;
    try {
      // thunkAPI.dispatch(setEmail(payload.email));
      return new BACKEND().send({
        type: "post",
        to: "/auth/reset",
        useAlert: true,
        payload: body,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "/auth/forgotPassword",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEmail(payload.email));
      return new BACKEND().send({
        type: "post",
        to: "/user/forgot-password-flow/initiate",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);
export const verifyForgotPassword = createAsyncThunk(
  "/auth/verifyForgotPassword",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(setOtp(payload?.verificationCode));
    try {
      return new BACKEND().send({
        type: "post",
        to: "/user/forgot-password-flow/verify-code",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const asyncLogin = createAsyncThunk(
  "/auth/login",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEmail(payload.email));

      return new BACKEND().send({
        type: "post",
        to: "/auth/login",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const AzureOAuth = createAsyncThunk(
  "/auth/azureAD",
  async (payload, thunkApi) => {
    try {
      // const login = (payload, history) => async (dispatch) => {
      //   // const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
      //   return BACKEND()
      //     .send({
      //       type: "post",
      //       to: "/auth/login",
      //       useAlert: true,
      //       payload,
      //     })
      //     .then((response) => {
      //       const { data, ok } = response;
      //       // responseHandler(response);
      //       if (ok) {
      //         let adminAuth = data && data.data && data.data.two_factor_auth;
      //         if (adminAuth === false) {
      //           Storage.set("admin-access-token", data.data.token);
      //           Storage.set("refresh-token", data.data.refresh_token);
      //           Storage.set("current-admin", JSON.stringify(data.data));
      //           Storage.set(
      //             "admin-access-data",
      //             JSON.stringify(data && data.data && data.data.user)
      //           );
      //           // const decoded = jwt_decode(data.data.token);
      //           // Storage.set("decoded-data", JSON.stringify(decoded));
      //           // routeAdmin(history);
      //         } else {
      //           history.push("/authenticate");
      //         }
      //       } else {
      //         // dispatch(loginFailure(data));
      //       }
      //     });
      // };
    } catch (error) {}
  }
);

export const fetchBranches = createAsyncThunk(
  "/auth/fetchBranches",
  async (search, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/branch?searchTerm=${search || ""}`,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const getUserById = createAsyncThunk(
  "/auth/getUserById",
  async (search, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/user/${JSON.parse(loggedInUser)?.userId}`,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const uploadFile = createAsyncThunk(
  "auth/uploadFile",
  async ({ formData: payload, config }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/upload-files",
        useAlert: true,
        header: { headers: { "Content-Type": "multipart/form-data" } },
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const logoutFromServer = createAsyncThunk(
  "auth/logoutFromServer",
  async (_, thunkAPI) => {
    try {
      return new BACKEND().send({
        to: `/auth/logout`,
        type: "get",
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

const initialState = {
  payload: {
    email: "",
    password: "",
  },
  otp: null,
  branches: [],
  loading: false,
  preloading: false,
  logoutLoading: false,
  user: null,
  appType: Storage.get(config.authProps[2]),
  modal: { open: false, close: false },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.payload = { ...state.payload, ...payload };
    },
    logout: (state) => {
      Storage.remove(config.authProps[0]);
      Storage.remove(config.authProps[1]);
      Storage.remove(config.authProps[2]);
    },

    switchApp: (state) => {
      state.preloading = true;
      const array = Object.values(AppType),
        previousAppType = Storage.get(config.authProps[2]),
        newAppType = array.filter((b) => b !== previousAppType)[0];

      Storage.set(config.authProps[2], newAppType);
      state.appType = newAppType;
      document.querySelector("body").style.overflowY = "hidden";
    },
    setModal: (state, { payload }) => {
      state.modal = {
        ...state.modal,
        ...payload,
      };
    },
    setCurrentScreen: (state, { payload }) => {
      state.currentScreen = payload;
    },
    setOtp: (state, { payload }) => {
      state.otp = payload;
    },
    setEmail: (state, { payload }) => {
      state.payload.email = payload;
    },
    setLogo: (state, { payload }) => {
      state.payload.logo = payload;
    },
    setImages: (state, { payload }) => {
      state.images = payload;
    },
    setPreloader: (state, { payload }) => {
      state.preloading = false;
      document.querySelector("body").style.overflowY = "scroll";
    },
  },
  extraReducers: (builder) => {
    /** Admin Banker Builder **/
    builder
      .addCase(asyncLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(asyncLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(asyncLogin.rejected, (state) => {
        state.loading = false;
      });
    /** Admin Banker Login Builder |END| **/

    /** Get User By Id Builder **/
    builder
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.user = payload?.data;
        }
      })
      .addCase(getUserById.rejected, (state) => {
        state.loading = false;
      });
    /** Get User By Id Builder |END| **/

    /** Submit Token Builder **/
    builder
      .addCase(submitToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.data?.token) {
          const decode = JSON.parse(
            AES.decrypt(payload?.data?.data, config.encryptionKey).toString(
              UTF8
            )
          );
          console.log(decode);
          Storage.set(config.authProps[0], payload?.data?.token);
          Storage.set(config.authProps[1], decode);
          Storage.set(config.authProps[2], config.appType);
        }
      })
      .addCase(submitToken.rejected, (state) => {
        state.loading = false;
      });
    /** Submit Token Builder |END| **/

    /** uploadFile **/
    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadFile.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.loading = false;
      });
    // changePassword
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchBranches.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBranches.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) state.branches = payload.data;
      })
      .addCase(fetchBranches.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) state.branches = payload.data;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) state.branches = payload.data;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(verifyForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyForgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) state.branches = payload.data;
      })
      .addCase(verifyForgotPassword.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(logoutFromServer.pending, (state) => {
        state.logoutLoading = true;
      })
      .addCase(logoutFromServer.fulfilled, (state, { payload }) => {
        state.logoutLoading = false;
      })
      .addCase(logoutFromServer.rejected, (state) => {
        state.logoutLoading = false;
      });
  },
});

export const getAuthData = (state) => state.auth;
export const {
  logout,
  setModal,
  setOtp,
  setEmail,
  setLogo,
  setImages,
  setCurrentScreen,
  setPayload,
  switchApp,
  setPreloader,
} = authSlice.actions;
export default authSlice.reducer;
