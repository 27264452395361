import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import jobs from './jobs/jobs'
import defaultState from "./defaultSlice"
import vendors from "./vendor/vendor";
import employees from "./employee/employee";
import manualReassignment from "./jobs/manualReassignment";
import dashboard from "./dashboard/dashboard";


export const store = configureStore({
 reducer: {
  defaultState,
  auth,
  jobs,
  vendors,
  employees,
  manualReassignment,
  dashboard
 }
})