import { LoadingButton } from "@mui/lab";
import React from "react";
import { getAmount } from "../../utils/helper";

const MakePayment = ({ data, action, loading }) => {
  return (
    <div className="text-center">
      <p className="text-xl text-bold">Total Jobs</p>
      <h3 className="text-2xl text-semibold my-2">
        {getAmount(data?.totalJobs)}
      </h3>

      {/* <br /> */}

      {/* <p className='text-xl text-bold'>Total Amount</p>
   <h3 className='text-xl text-semibold'>₦{getAmount(data?.totalAmount)}</h3> */}

      <LoadingButton
        onClick={action}
        size="large"
        loading={loading}
        variant="contained"
        color="error"
        className="capitalize px-5 rounded"
        style={{
          padding: "10px 20px",
          textTransform: "capitalize",
          margin: "1em",
        }}
      >
        Mark Now
      </LoadingButton>
    </div>
  );
};

export default MakePayment;
