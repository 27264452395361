import React, { useEffect } from "react";
import * as echarts from "echarts";

const PieChart = ({ data, color, id, pieChartClass, wrapperClass }) => {
  useEffect(() => {
    const myChart = echarts.init(document.getElementById(id));
    const options = {
      legend: {
        orient: "vertical",
        x: "left",
        data: data,
        top: 40,
        left: 0,
        textStyle: {
          color: "black",
          fontSize: 12,
          fontWeight: "bold",
        },
        itemGap: 10,
      },
      series: [
        {
          type: "pie",
          color: color,
          radius: ["40%", "80%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "top",
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
              fontSize: "30",
              fontWeight: "bold",
            },
          },
          itemStyle: {
            borderWidth: 4,
            borderColor: "#fff",
            borderRadius: 6,
          },
          data: data,
        },
      ],
    };
    myChart.setOption(options);

    return () => {
      myChart.dispose();
    };
  }, [data, color, id]);

  return (
    <div className={` ${wrapperClass}`}>
      <div id={id} className={` ${pieChartClass} flex flex-wrap`}></div>
    </div>
  );
};

export default PieChart;
