/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobsData, rejectAndReassign } from "../../features/jobs/jobs";
import { useFormik } from "formik";
import { vRejectAndReassign } from "../../utils/validators";
import { getId } from "../../utils/helper";
import SelectInput from "../../components/forms/SelectInput";
import { useEffect } from "react";
import {
  fetchVendorsByStateId,
  getVendorsData,
  setPagination,
} from "../../features/vendor/vendor";
import Alert from "../../utils/alert";

function VendorAssignment({ data, closeModal, refresh }) {
  const dispatch = useDispatch(),
    { modal, rejectAndReassignPayload } = useSelector(getJobsData),
    { vendors, pagination, loading } = useSelector(getVendorsData),
    {
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      errors,
      touched,
    } = useFormik({
      validationSchema: vRejectAndReassign,
      initialValues: rejectAndReassignPayload,
      onSubmit: async (values) => {
        const payload = {
          ...values,
          accountId: data?.AcctRefID,
          bankUserId: getId()?.bank,
        };

        const res = await dispatch(rejectAndReassign(payload)).unwrap();
        if (res?.success) {
          Alert({ type: "success", message: "Job re-assigned successfully" });
          refresh?.();
        }
      },
    });

  useEffect(() => {
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) =>
          (async () => await dispatch(fetchVendorsByStateId(data?.StateId)))(),
        700
      );
    } else
      cb = (async () => await dispatch(fetchVendorsByStateId(data?.StateId)))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search]);

  useEffect(() => {
    data?.StateId === null &&
      Alert({
        timer: 10000,
        type: "error",
        message:
          "This Job is not associated to any state, therefore, no vendor can be assigned to it.",
      });
  }, []);

  const options = useMemo(() => {
    return (
      vendors
        // eslint-disable-next-line array-callback-return
        ?.map((vendor) => {
          if (
            data?.AssignedVendor?.VendorPreferredName !==
              vendor?.VendorPreferredName ||
            data?.AssignedVendor?.VendorName !== vendor?.VendorName
          ) {
            return {
              value:
                vendor?.VendorPreferredName ||
                vendor?.VendorName ||
                "Vendor " + vendor?.VendorID,
              id: vendor?.VendorID,
            };
          }
        })
        ?.filter(Boolean)
    );
  }, [loading]);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      {values.updatedAddress && (
        <>
          <div>
            <label htmlFor="updatedAddress" className="font-[600]">
              Address
            </label>
            <TextField
              readOnly
              value={values?.updatedAddress}
              type="text"
              name="updatedAddress"
              id="updatedAddress"
              className="w-full border outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] p-0"
              placeholder="e.g 13 Jimoh Bologun Street"
              required
            />
          </div>
          <br />
        </>
      )}

      <div>
        <label htmlFor="" className="font-[600]">
          From Vendor
        </label>
        <TextField
          value={data?.AssignedVendor?.VendorName || "John Doe"}
          type="text"
          name="fromVendor"
          id="fromVendor"
          className="w-full border outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] p-0"
          placeholder="John Doe"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors.fromVendor && touched.fromVendor}
          helperText={
            errors.fromVendor && touched.fromVendor ? errors.fromVendor : ""
          }
        />
      </div>

      <br />
      <div>
        <label htmlFor="" className="font-[600]">
          To Vendor
        </label>

        <SelectInput
          {...{
            className:
              "w-full border-none outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] rounded",
            value: values.newVendorId,
            name: "newVendorId",
            handleChange,
            handleBlur,
            handleFocus,
            searchValue: pagination.search,
            searchLoading: pagination.search && loading,
            loading,
            handleSearch,
            error: errors.newVendorId && touched.newVendorId,
            helperText: errors.newVendorId,
            options: options,
          }}
        />
      </div>

      <div className="flex justify-end capitalize">
        <div className="inline-block">
          <LoadingButton
            type="submit"
            variant="text"
            loading={modal.loader.rejectReassign}
            sx={{
              height: "2.3rem",
              alignItems: "center",
              px: "1em",
              fontSize: "semibold",
              color: "red",
              background: "#f5ebeb",
              textTransform: "capitalize",
            }}
          >
            {" "}
            Reassign{" "}
          </LoadingButton>
          <Button
            onClick={closeModal}
            variant="contained"
            color="error"
            className="capitalize mr-5"
            style={{ textTransform: "capitalize", margin: "2em" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default VendorAssignment;
