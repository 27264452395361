import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";
import { AppType, getRole, Roles } from "../../utils/helper";

const role =
  getRole() === Roles.bank
    ? "banker"
    : getRole() === Roles.superAdmin
    ? "admin"
    : getRole();

export const fetchDashboardStats = createAsyncThunk(
  "/dashboard/fetchDashboardStats",
  async (_, thunkAPI) => {
    const { filter } = thunkAPI.getState().dashboard,
      { appType } = thunkAPI?.getState()?.auth;

    try {
      let url = `/account/${role}/dashboard/stats`;
      const user = thunkAPI.getState().auth.user;
      if (appType === AppType.cs || user?.Vendors[0]?.Type === "solicitor")
        url = "/corporate" + url;

      if ([Roles.admin, Roles.superAdmin].includes(getRole()))
        url = url + `/${filter}`;

      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

const initialState = {
  loading: false,
  filter: "30_DAYS",
  stats: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardStats.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(fetchDashboardStats.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          console.log(payload);
          state.stats = payload;
        }
      })
      .addCase(fetchDashboardStats.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const getDashboardData = (state) => state.dashboard;
export const { setFilter } = dashboardSlice.actions;
export default dashboardSlice.reducer;
