import { LoadingButton } from "@mui/lab";
import React from "react";
import { useDispatch } from "react-redux";
import CustomModal from "./Index";

const ConfirmationModal = ({
  close,
  open,
  data,
  refresh,
  action,
  loading,
  title,
  subtitle,
  btnTexts,
}) => {
  const dispatch = useDispatch();
  const handleSubmit = async function () {
    const res = await dispatch(action(data)).unwrap();
    if (res?.success) {
      close();
      refresh?.();
    }
  };

  return (
    <CustomModal
      {...{
        open,
        close,
      }}
    >
      <div className="text-center">
        <h1 className="-mt-5 text-[30px] font-[600]">{title}</h1>
        <div className="text-[20px] mt-2 leading-[32px] font-[400]">
          {subtitle}{" "}
        </div>
        <div className="my-8">
          <button
            className="h-[2.8rem] w-[150px] capitalize items-center rounded-md px-3  text-sm font-semibold text-dark_red bg-[#f5ebeb] hover:bg-[#ffe1e0] mr-5"
            size="large"
            type="button"
            onClick={close}
          >
            {btnTexts?.[0]}
          </button>
          <LoadingButton
            style={{ width: "150px", textTransform: "capitalize" }}
            className="h-[2.8rem] items-center rounded-md px-3  text-sm font-semibold bg-dark_red text-white hover:bg-light_red"
            variant="contained"
            color="error"
            sx={{
              ".MuiLoadingButton-loadingIndicatorCenter": {
                color: "var(--C_primary) !important",
              },
            }}
            onClick={handleSubmit}
            type="submit"
            disabled={loading}
            loading={loading}
          >
            {btnTexts[1]}
          </LoadingButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
