/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { fetchStates, getDefaultState } from "../../features/defaultSlice";
import HomeLayout from "../../layouts/Home";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  createVendor,
  getVendorsData,
  updateVendor,
} from "../../features/vendor/vendor";
import { vcreateVendor } from "../../utils/validators";
import { LoadingButton } from "@mui/lab";
import MultipleSelect from "../../components/forms/MultipleSelect";

const CreateVendor = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    dispatch = useDispatch(),
    { model, loading } = useSelector(getVendorsData),
    { states } = useSelector(getDefaultState),
    {
      setValues,
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      errors,
      touched,
    } = useFormik({
      validationSchema: vcreateVendor,
      initialValues: model,
      onSubmit: async ({ companyName, ...payload }) => {
        let action = createVendor;
        const values = { ...payload };
        if (state) {
          action = updateVendor;
          values.userId = state?.UserId;
        }

        values.stateIds = payload?.stateIds?.map((state) =>
          getStateIdByName(state)
        );
        values.vendorAgencyName = companyName;
        const res = await dispatch(action(values)).unwrap();
        res?.success && navigate(-1);
      },
    });

  useEffect(() => {
    dispatch(fetchStates());
    state === null &&
      setValues({
        firstName: "",
        lastName: "",
        email: "",
        vendorPreferredName: "",
        companyName: "",
        accountNumber: "",
        stateIds: [],
      });
  }, []);

  const statesArray = useMemo(() => {
    return states?.map((state) => state?.Name);
  }, [states.length]);

  function getStateIdByName(stateName) {
    const res = states.find((state) => state?.Name === stateName);
    return res?.Id;
  }

  return (
    <HomeLayout {...{ navigation: false }}>
      <div className="-mt-[5%]">
        <div className="">
          <Box className="text-[var(--c-dark-1)] flex mb-5">
            <span
              className="flex cursor-pointer items-center hover:bg-[#eee] rounded-md px-1"
              onClick={(_) => navigate(-1)}
            >
              <ArrowBackIosNewIcon className="mr-2" fontSize="small" />
              <Typography className="text-[400] text-[16px] ">Back</Typography>
            </span>
          </Box>
          <Typography variant="h6" className="text-2xl">
            Registered Vendor
          </Typography>

          <div
            className="py-10 my-5 px-[5%] bg-[#EAEAEA4D] mx-[10%]"
            style={{ borderTopLeftRadius: "100" }}
          >
            <Typography className="text-[400] text-2xl mt-8 ml-3 text-[#040316]">
              {state ? "Edit" : "New"} Vendor
            </Typography>
            <Box
              className="rounded-lg lg:px-[4em] px-[1em]"
              sx={{
                className: "w-[100%] lg:w-[80%]",
                py: "2em",
              }}
              noValidate
              component="form"
              onSubmit={handleSubmit}
            >
              <Box className="grid lg:grid-cols-2 gap-4   m-auto">
                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="firstName"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    First Name
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                  />
                </FormControl>
                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="lastName"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Last Name
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ""
                    }
                  />
                </FormControl>

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="companyName"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Company | Vendor Name
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="companyName"
                    name="companyName"
                    value={values.companyName}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.companyName && touched.companyName}
                    helperText={
                      errors.companyName && touched.companyName
                        ? errors.companyName
                        : ""
                    }
                  />
                </FormControl>
                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="vendorPreferredName"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Preferred Name
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="vendorPreferredName"
                    name="vendorPreferredName"
                    value={values.vendorPreferredName}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.vendorPreferredName && touched.vendorPreferredName
                    }
                    helperText={
                      errors.vendorPreferredName && touched.vendorPreferredName
                        ? errors.vendorPreferredName
                        : ""
                    }
                  />
                </FormControl>

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Email Address
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="email"
                    name="email"
                    value={values.email}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                  />
                </FormControl>

                {/* <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="accountNumber"
                    className="block mb-1 text-[16px] font-medium text-[#090914]"
                  >
                    Account Number
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="accountNumber"
                    name="accountNumber"
                    value={values.accountNumber}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.accountNumber && touched.accountNumber}
                    helperText={
                      errors.accountNumber && touched.accountNumber
                        ? errors.accountNumber
                        : ""
                    }
                  />
                </FormControl> */}

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="stateCoverage"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    State Coverage
                  </label>

                  <MultipleSelect
                    {...{
                      className: "w-full mx-auto",
                      options: statesArray?.sort(),
                      value: values.stateIds,
                      name: "stateIds",
                      required: true,
                      handleBlur,
                      handleFocus,
                      handleChange,
                      loading: false,
                      searchLoading: false,
                      error: errors.stateIds && touched.stateIds,
                      helperText: errors.stateIds,
                    }}
                  />
                </FormControl>
              </Box>
              <Box className="flex justify-end mt-5">
                <button
                  onClick={(_) => navigate(-1)}
                  className="inline-fle h-[2.3rem] w-[150px] items-center rounded-md px-3  text-sm font-semibold text-dark_red bg-[#f5ebeb] hover:bg-[#ffe1e0] mr-5"
                  size="large"
                  type="button"
                >
                  Cancel
                </button>

                <LoadingButton
                  style={{ width: "150px" }}
                  className="h-[2.3rem] items-center rounded-md px-3  text-sm font-semibold bg-dark_red text-white hover:bg-light_red"
                  variant="contained"
                  color="error"
                  sx={{
                    ".MuiLoadingButton-loadingIndicatorCenter": {
                      color: "var(--C_primary) !important",
                    },
                    textTransform: "capitalize",
                  }}
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  fullWidth
                >
                  {state ? "Save Changes" : "Submit"}
                </LoadingButton>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default CreateVendor;
