import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";
import config from "../../utils/config";
import { AppType, Roles } from "../../utils/helper";

export const fetchEmployees = createAsyncThunk(
  "/employees",
  async (role = "role=bank_staff", thunkAPI) => {
    const { pagination } = thunkAPI.getState().employees;
    try {
      const appType = config.appType === AppType.cav ? "CAV" : "CSP";
      const query =
        role === Roles.bank
          ? `permittedApp=${appType}&role=${Roles.bank}`
          : role === Roles.admin
          ? `role=${Roles.admin}`
          : `deleted=${true}&role=${Roles.admin}&role=${Roles.bank}`;

      return new BACKEND().send({
        type: "get",
        to: `/user?${query}&pageNumber=${pagination?.page}&pageSize=${
          pagination?.pageSize
        }&searchTerm=${pagination?.search || ""}`,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const createEmployee = createAsyncThunk(
  "/employees/create",
  async (payload, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: `/user/create-banker`,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const createAdmin = createAsyncThunk(
  "/employees/createAdmin",
  async (payload, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: `/user/admin-user/create`,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const setCopiedData = createAction("vendors/setCopiedData");

export const updateUser = createAsyncThunk(
  "/employees/update",
  async (payload, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/user`,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "/employees/delete",
  async ({ id }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "delete",
        to: `/user/soft/delete/${id}`,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

const initialState = {
  employees: [],
  currentData: {},
  loading: false,
  modalLoading: false,
  searchTerm: "",
  copiedData: "",
  printMod: { enable: false, ref: null, pageTitle: "" },
  downloadExcelUrl: config.backendUrl.endsWith("/")
    ? config.backendUrl + "user/download/user-list/excel"
    : config.backendUrl + "/user/download/user-list/excel",
  downloadPdfUrl: config.backendUrl.endsWith("/")
    ? config.backendUrl + "user/download/user-list/pdf"
    : config.backendUrl + "/user/download/user-list/pdf",
  modal: {
    open: false,
    close: false,
    loader: {
      rejectReassign: false,
      reject: false,
      confirm: false,
      closedJobs: false,
      delete: false,
    },
  },
  changePasswordPayload: { password: "", userId: "" },
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
  },
  model: {
    firstName: "",
    lastName: "",
    email: "",
    employeeId: "",
    role: "",
    branchId: "",
    permittedApps: [],
  },
};

export const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setPrintMod: (state, { payload }) => {
      state.printMod = { ...state.printMod, ...payload };
    },
    setPayload: (state, { payload }) => {
      state.model = { ...state.model, ...payload };
    },
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    openModal: (state, { payload }) => {
      state.modal[payload || "open"] = true;
    },
    closeModal: (state, { payload }) => {
      state.modal[payload || "open"] = false;
    },
    setCurrentData: (state, { payload }) => {
      state.currentData = payload;
    },
    setSearch: (state, { payload }) => {
      state.searchTerm = payload;
    },
    setCopiedData: (state, { payload }) => {
      state.copiedData = payload;
    },
  },
  extraReducers: (builder) => {
    /** Fetch Employees Job Builder **/
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state.employees = payload?.data;
        }
      })
      .addCase(fetchEmployees.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.modalLoading = false;
      });
    builder
      .addCase(createEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createEmployee.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createAdmin.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.modalLoading = false;
      });
    /** Fetch Employees Job Builder |END| **/
    // Storage.set(config.authProps[1], payload?.data)
  },
});

export const getEmployeesData = (state) => state.employees;
export const {
  setPagination,
  openModal,
  closeModal,
  setPrintMod,
  setCurrentData,
  setPayload,
} = employeeSlice.actions;

export default employeeSlice.reducer;
