import { CircularProgress } from '@mui/material';
import React from 'react';

const Preloader = ({preloading}) => {
  return (
    <div>
      {preloading && (
        <div
          className="items-center justify-center"
          style={{
            display: "flex",
            background: "rgba(0,0,0,.8)",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1500,
          }}
        >
          <CircularProgress
            sx={{ color: "var(--C_primary)" }}
            color="inherit"
            size={50}
          />
        </div>
      )}
    </div>
  );
}

export default Preloader;
