import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { vNewAddress } from "../../utils/validators";
import { closeJob, getJobsData, initUpdateAddress, setRejectAndReassignPayload } from "../../features/jobs/jobs";

function UpdateAddress({ data, closeModal, refresh, openVendorModal }) {
	const dispatch = useDispatch(),
		{ modal, newAddressPayload, modalLoading } = useSelector(getJobsData),
		{
			values,
			handleBlur,
			handleChange,
			handleFocus,
			handleSubmit,
			errors,
			touched,
		} = useFormik({
			validationSchema: vNewAddress,
			initialValues: newAddressPayload,
			onSubmit: async (values) => {
				const payload = {
					...values,
					accountId: data?.AcctRefID,
				};

				const res = await dispatch(initUpdateAddress(payload)).unwrap();
				res?.success && refresh?.();
			},
		});

		const handleCloseJob = async _=> {
			const res = await dispatch(closeJob([data?.AcctRefID])).unwrap();
			res?.success && refresh?.()
	}
	const handleModal = _=> {
		dispatch(setRejectAndReassignPayload({updatedAddress: values?.newAddress}))
		openVendorModal()
	}

	return (
		<Box component="form" noValidate onSubmit={handleSubmit}>
			<div>
				<label htmlFor="" className="font-[600]">
				Address
				</label>
				<TextField
					value={values?.newAddress}
					type="text"
					name="newAddress"
					id="newAddress"
					className="w-full border outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] p-0"
					placeholder="John Doe"
					required
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					error={errors.newAddress && touched.newAddress}
					helperText={
						errors.newAddress && touched.newAddress ? errors.newAddress : ""
					}
				/>
			</div>

			<div className="flex justify-end capitalize">
				<div className="inline-block">
				<LoadingButton
						variant="outlined"
						className="mr-2"
						color="error"
						loading={modal.loader.closedJobs}
						onClick={handleCloseJob}
						sx={{
							height: "2.3rem",
							alignItems: "center",
							px: "1em",
							fontSize: "semibold",
							textTransform: "capitalize",
							margin: "2em"
						}}>
						{" "}
						Move to closed job
					</LoadingButton>

					<Button
						variant="text"
            onClick={handleModal}
						sx={{
							height: "2.3rem",
							alignItems: "center",
							px: "1em",
							fontSize: "semibold",
							color: "red",
							background: "#f5ebeb",
							textTransform: "capitalize",
						}}>
						{" "}
						Reassign Vendor
					</Button>
					<LoadingButton
						type="submit"
						loading={modalLoading}
						variant="contained"
						color="error"
						className="capitalize"
						style={{ textTransform: "capitalize", margin: "2em 0 2em 2em" }}>
						Update Address
					</LoadingButton>
				</div>
			</div>
		</Box>
	);
}

export default UpdateAddress;
