/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { getRole, Roles } from "../../utils/helper";
import BankDash from "./BankDash";
import AdminDash from "./AdminDash";
import VendorsDash from "./VendorsDash";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardStats,
  getDashboardData,
} from "../../features/dashboard/dashboard";
import { useEffect } from "react";
import { getAuthData } from "../../features/auth/authSlice";
const { admin, bank, superAdmin, vendor } = Roles;

const Dashboard = () => {
  const dispatch = useDispatch(),
    { filter } = useSelector(getDashboardData),
    { preloading } = useSelector(getAuthData),
    [Dash, setDash] = useState("");

  useEffect(() => {
    (async (_) => await dispatch(fetchDashboardStats()))();
  }, [filter, preloading]);

  const role = getRole();

  useEffect(() => {
    if (getRole()) {
      if (role === bank) {
        setDash(<BankDash />);
      } else if ([admin, superAdmin].includes(role)) {
        setDash(<AdminDash />);
      } else if (role === vendor) {
        setDash(<VendorsDash />);
      }
    }
  }, []);

  return Dash;
};

export default Dashboard;
