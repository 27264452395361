import { Box } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import React from "react";

function ViewRejectionReason({ data, isCorporate }) {
  console.log(data);
  return (
    <Box>
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">Banker's Comment</p>
        <p>
          {data?.VendorJobLedgers?.[0]?.Comment ||
            data?.CorporateVendorJobLedgers?.[0]?.Comment ||
            "Not Available"}
        </p>
      </div>
      <br />
      {isCorporate && (
        <div className="flex justify-between w-full">
          <p className="font-[600]">Banker's Report</p>

          {data?.CorporateJobRejections?.[0]?.BankRejectionImages?.[0] ? (
            <a
              href={data?.CorporateJobRejections?.[0]?.BankRejectionImages?.[0]}
              alt="#"
              download={true}
              className="mt-2 flex items-end"
            >
              <CloudDownloadIcon color="error" />
              <span className="text-[#D70900] ml-1">
                {" "}
                Download Bank Rejection Image{" "}
              </span>
            </a>
          ) : (
            <p>Not Available</p>
          )}
        </div>
      )}
    </Box>
  );
}

export default ViewRejectionReason;
