import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";
import config from "../../utils/config";
import { AppType, Roles, getId, getRole } from "../../utils/helper";

export const fetchVendorsAwaitingPayments = createAsyncThunk(
  "/jobs/fetchVendorsAwaitingPayments",
  async (_, thunkAPI) => {
    const role = [Roles.admin, Roles.superAdmin, Roles.bank].includes(getRole())
      ? "banker"
      : getRole();
    const { pagination } = thunkAPI.getState().vendors,
      { appType } = thunkAPI?.getState()?.auth;

    let url = `/account/${role}/awaiting-payment/vendor-list/${
      getId().user
    }?pageNumber=${pagination?.page}&pageSize=${
      pagination?.pageSize
    }&searchTerm=${pagination?.search || ""}`;

    if (appType === AppType.cs) url = "/corporate" + url;
    console.log(appType);
    try {
      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const fetchVendorAwaitingPaymentById = createAsyncThunk(
  "/jobs/fetchVendorAwaitingPaymentById",
  async (vendorId, thunkAPI) => {
    const role = [Roles.admin, Roles.bank, Roles.superAdmin].includes(getRole())
      ? "banker"
      : getRole();
    const { pagination } = thunkAPI.getState().vendors,
      { appType } = thunkAPI?.getState()?.auth;

    let url = `/account/${role}/awaiting-payment/vendor-list/${
      getId().user
    }/${vendorId}?pageNumber=${pagination?.page}&pageSize=${
      pagination?.pageSize
    }&searchTerm=${pagination?.search || ""}`;

    if (appType === AppType.cs) url = "/corporate" + url;
    console.log(appType);

    try {
      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const fetchVendors = createAsyncThunk(
  "/vendors",
  async (searchTerm, thunkAPI) => {
    let query = "type=vendor&";
    const { pagination } = thunkAPI.getState().vendors,
      { appType } = thunkAPI?.getState()?.auth;

    if (appType === AppType.cs) query = "type=solicitor&";

    let url = `/vendors?${query}pageNumber=${pagination?.page}&pageSize=${
      pagination?.pageSize
    }&searchTerm=${pagination?.search || searchTerm || ""}&deleted=${
      pagination.deleted
    }`;

    if ([true, false].includes(pagination.status)) {
      url = url + `&approved=${pagination.status}`;
    }

    try {
      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const fetchVendorsByStateId = createAsyncThunk(
  "/vendors/fetchVendorsByStateId",
  async (stateIds, thunkAPI) => {
    // stateIds = stateIds instanceof Array ? stateIds : [stateIds];
    const { pagination } = thunkAPI.getState().vendors;
    let query = "vendorType=vendor&",
      { appType } = thunkAPI?.getState()?.auth;

    if (appType === AppType.cs) query = "vendorType=solicitor&";
    try {
      return new BACKEND().send({
        type: "get",
        to: `/vendors/find-vendor/by-state-coverage/[${
          // stateIds instanceof Array ? stateIds?.join?.(" ") :
          stateIds
        }]?${query}searchTerm=${pagination.search || ""}`,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
      console.log(error);
    }
  }
);

export const createVendor = createAsyncThunk(
  "/vendors/create",
  async (payload, thunkAPI) => {
    let url = "/user/create-vendor/template/vendor";
    const { appType } = thunkAPI?.getState()?.auth;
    if (appType === AppType.cs) url = "/user/create-vendor/template/solicitor";
    console.log(appType);
    try {
      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const setCopiedData = createAction("vendors/setCopiedData");

export const updateVendor = createAsyncThunk(
  "/vendors/update",
  async ({ newPassword, ...payload }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/user/update-vendor`,
        useAlert: true,
        payload: { ...payload, password: newPassword },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

const initialState = {
  vendors: [],
  currentData: {},
  loading: false,
  modalLoading: false,
  searchTerm: "",
  copiedData: "",
  printMod: { enable: false, ref: null, pageTitle: "" },
  downloadExcelUrl: config.backendUrl.endsWith("/")
    ? config.backendUrl + "vendors/download/vendor-list/excel"
    : config.backendUrl + "/vendors/download/vendor-list/excel",
  downloadPdfUrl: config.backendUrl.endsWith("/")
    ? config.backendUrl + "vendors/download/vendor-list/pdf"
    : config.backendUrl + "/vendors/download/vendor-list/pdf",
  modal: {
    open: false,
    close: false,
    loader: {
      rejectReassign: false,
      reject: false,
      confirm: false,
      closedJobs: false,
    },
  },
  changePasswordPayload: { password: "", userId: "" },
  pagination: {
    deleted: false,
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
    status: "",
  },
  model: {
    firstName: "",
    lastName: "",
    email: "",
    stateIds: [],
    // accountNumber: "",
    companyName: "",
    vendorPreferredName: "",
  },
};

export const vendorSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    clearVendorData: (state) => {
      state.vendors = [];
    },
    setPrintMod: (state, { payload }) => {
      state.printMod = { ...state.printMod, ...payload };
    },
    setPayload: (state, { payload }) => {
      state.model = { ...state.model, ...payload };
    },
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    openModal: (state, { payload }) => {
      state.modal[payload || "open"] = true;
    },
    closeModal: (state, { payload }) => {
      state.modal[payload || "open"] = false;
    },
    setCurrentData: (state, { payload }) => {
      state.currentData = payload;
    },
    setSearch: (state, { payload }) => {
      state.searchTerm = payload;
    },
    setCopiedData: (state, { payload }) => {
      state.copiedData = payload;
    },
  },
  extraReducers: (builder) => {
    /** Fetch Vendors Builder **/
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVendors.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state.vendors = payload?.data;
        }
      })
      .addCase(fetchVendors.rejected, (state) => {
        state.loading = false;
      });
    /** Fetch Vendors Builder |END| **/

    /** fetchVendorsByStateId  Builder **/
    builder
      .addCase(fetchVendorsByStateId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVendorsByStateId.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state.vendors = payload?.data;
        }
      })
      .addCase(fetchVendorsByStateId.rejected, (state) => {
        state.loading = false;
      });
    /** Fetch Vendors Builder |END| **/

    /** fetchVendorsAwaitingPayments  Builder **/
    builder
      .addCase(fetchVendorsAwaitingPayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVendorsAwaitingPayments.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state.vendors = payload?.data;
        }
      })
      .addCase(fetchVendorsAwaitingPayments.rejected, (state) => {
        state.loading = false;
      });
    /** fetchVendorsAwaitingPayments Builder |END| **/

    /** fetchVendorsAwaitingPayments  Builder **/
    builder
      .addCase(fetchVendorAwaitingPaymentById.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchVendorAwaitingPaymentById.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          if (payload?.success) {
            state.pagination.total = payload?.pagination?.totalCount;
            state.pagination.length =
              state.pagination.pageSize * state.pagination.page;
            state.vendors = payload?.data;
          }
        }
      )
      .addCase(fetchVendorAwaitingPaymentById.rejected, (state) => {
        state.loading = false;
      });
    /** Fetch Vendors Builder |END| **/

    /** createVendor Builder **/
    builder
      .addCase(createVendor.pending, (state) => {
        state.loading = true;
      })
      .addCase(createVendor.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createVendor.rejected, (state) => {
        state.loading = false;
      });
    /** createVendor Builder |END| **/

    /** Update Vendors Builder **/
    builder
      .addCase(updateVendor.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVendor.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateVendor.rejected, (state) => {
        state.loading = false;
      });
    /** Update Vendors Builder |END| **/
  },
});

export const getVendorsData = (state) => state.vendors;
export const {
  setPagination,
  openModal,
  closeModal,
  setPrintMod,
  setCurrentData,
  setPayload,
  clearVendorData,
} = vendorSlice.actions;

export default vendorSlice.reducer;
