import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../forms/SelectInput";
import { useLocation } from "react-router-dom";
import { getJobsData, moveJobManually } from "../../features/jobs/jobs";
import Alert from "../../utils/alert";

const MoveJobs = ({ refresh, data, closeModal }) => {
  const { modalLoading } = useSelector(getJobsData),
    { pathname } = useLocation(),
    dispatch = useDispatch(),
    [queue, setQueue] = useState(""),
    queuesList = [
      "Rejected Jobs",
      "Closed Jobs",
      "Awaiting Payment",
      "Pending Jobs",
      "Paid Jobs",
      "Pending Acknowledgement",
      "Awaiting Branch Confirmation",
    ];

  // removes the current page's queue from the list
  const queues = queuesList.filter((queue) => {
    return (
      queue.toLocaleLowerCase() !==
      (pathname === "/awaiting-bank-confirmation"
        ? "/awaiting-branch-confirmation"
        : pathname
      )
        .substring(1)
        .replaceAll("-", " ")
        .toLocaleLowerCase()
    );
  });

  async function moveJobs(e) {
    e.preventDefault();
    let que = queue;
    // removes S from the queue that doesn't have
    if (queue.endsWith("s") && queue !== "Pending Jobs") {
      que = queue.slice(0, queue.length - 1);
    }

    const payload = {
      queue: que.toUpperCase().replaceAll(" ", "_"),
      accountIds: data.map((d) => d?.AcctRefID),
    };

    const res = await dispatch(moveJobManually(payload)).unwrap();
    if (res?.success) {
      Alert({ type: "success", message: "Job(s) moved successfully" });
      closeModal();
      refresh();
    }
  }

  return (
    <Box component="form" noValidate onSubmit={moveJobs}>
      <div>
        <label htmlFor="" className="font-[600]">
          To where?
        </label>

        <SelectInput
          {...{
            className:
              "w-full border-none outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] rounded",
            value: queue,
            handleChange: ({ target: { value } }) => setQueue(value),
            error: false,
            options: queues,
          }}
        />
      </div>

      <div className="flex justify-end capitalize">
        <div className="inline-block">
          <Button
            onClick={closeModal}
            variant="contained"
            color="error"
            style={{ textTransform: "capitalize", margin: "2em" }}
          >
            No
          </Button>

          <LoadingButton
            type="submit"
            variant="text"
            loading={modalLoading}
            disabled={!queue}
            sx={{
              height: "2.3rem",
              alignItems: "center",
              px: "1em",
              fontSize: "semibold",
              color: "red",
              background: "#f5ebeb",
              textTransform: "capitalize",
            }}
          >
            {" "}
            Yes
          </LoadingButton>
        </div>
      </div>
    </Box>
  );
};

export default MoveJobs;
