import { Box } from "@mui/material";
import React from "react";
import { Roles } from "../../utils/helper";

function ViewEmployee({ data }) {
  return (
    <Box>
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">Name</p>
        <p>{data?.firstName + " " + data?.lastName}</p>
      </div>

      <br />
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">Employee ID</p>
        <p>{data?.employeeId}</p>
      </div>

      <br />
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">Email Address</p>
        <p>{data?.email}</p>
      </div>

      <br />
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">Branch</p>
        <p>{data?.branch?.Name?.split(" ")?.[0]}</p>
      </div>

      <br />
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">Role</p>
        <p className="capitalize">
          {data?.role === Roles.bank ? "Banker" : "Admin"}
        </p>
      </div>
    </Box>
  );
}

export default ViewEmployee;
