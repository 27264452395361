import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getRole } from "../../utils/helper";
import { useDraggable } from "react-use-draggable-scroll";

export default function TabComponent({ links }) {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const { events } = useDraggable(ref);

  useEffect(() => {
    const currentTab = links.find(
      (tab) =>
        tab.url === location.pathname || location.pathname.includes(tab.url)
    );
    setActiveTab(currentTab?.id || 0);
    document
      .querySelector(`#${currentTab?.name.split(" ")[0]}`)
      ?.scrollIntoView(true);
  }, [location.pathname, links]);

  const handleTabClick = (index) => {
    const clickedTab = links.find((tab) => tab.id === index);
    if (clickedTab) {
      setActiveTab(clickedTab.id);
    }
  };

  if (!links[activeTab]) {
    return null;
  }

  return (
    <div className="w-[97%] mx-auto">
      {/* MOBILE */}
      <div className="sm:hidden w-[97%] m-auto">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block shadow w-full py-3 px-2 rounded-md"
          value={links[activeTab].name}
          onChange={(e) => {
            const selectedTab = links.find(
              (tab) => tab.name === e.target.value
            );
            setActiveTab(links.indexOf(selectedTab));
            navigate(selectedTab?.url);
          }}
        >
          {links.map(
            (tab) =>
              (tab.authorizedUsers.includes(getRole()) ||
                tab.authorizedUsers.includes("all")) && (
                <option key={tab.id}>{tab.name}</option>
              )
          )}
        </select>
      </div>
      {/* DESKTOP */}
      <div className="hidden sm:block">
        <nav
          {...events}
          ref={ref}
          className="tab-component-bs cursor-grab overflow-x-auto w-full flex justify-between whitespace-nowrap xl:space-x-4 xlg:space-x-1 lg:space-x-5 md:space-x-1 rounded-[10px] bg-[#F4F4F4] p-4"
          aria-label="Tabs"
          // onScroll={console.log}
        >
          {links.map(
            (tab) =>
              (tab.authorizedUsers.includes(getRole()) ||
                tab.authorizedUsers.includes("all")) && (
                <Link
                  key={tab.id}
                  id={tab.name.split(" ")[0]}
                  to={tab.url}
                  className={` text-gray-600 font-extrabold rounded-md lg:px-3 sm:px-2 lg:py-2 sm:py-1 md:py-2 xl:text-[15px] xlg:text-[12px] lg:text-[11px] cursor-pointer md:text-[7px] ${
                    activeTab === tab.id
                      ? "bg-dark_red text-white cursor-pointer md:text-[7px]"
                      : " "
                  }`}
                  aria-current={activeTab === tab.id ? "page" : undefined}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.name}
                </Link>
              )
          )}
        </nav>
      </div>
      <div className="mt-8 hidden sm:block ">{links[activeTab].content}</div>
    </div>
  );
}
