import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import config from "../../utils/config";
import BACKEND from "../../utils/backend";
import Storage from "../../utils/storage";
import Alert from "../../utils/alert";
import {
  AppType,
  camelCasedPathName,
  getId,
  getRole,
  Roles,
} from "../../utils/helper";

export const initUpdateAddress = createAsyncThunk(
  "/account/bank-action/update-address",
  async (payload, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/account/bank-action/update-address";
      if (appType === AppType.cs)
        url = "/corporate/account/banker/bank-action/update-rc-number";

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const updateVendorsForm = createAsyncThunk(
  "/jobs/vendorsForm",
  async (payload, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/account/vendor-action/complete-job";
      if (appType === AppType.cs)
        url = "/corporate/account/vendor/pending-jobs/complete-job";

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const rejectAndReassign = createAsyncThunk(
  "/jobs/pending/acknowledgement/rejectAndReassign",
  async (payload, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/account/bank-action/reject-and-reassign-job";
      if (appType === AppType.cs)
        url = "/corporate/account/banker/bank-action/reject-and-reassign-job";

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const closeJob = createAsyncThunk(
  "/jobs/close-job",
  async (accountIds, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/account/bank-action/close-job";
      if (appType === AppType.cs)
        url = "/corporate/account/banker/bank-action/close-job";

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload: {
          accountIds,
        },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const rejectJob = createAsyncThunk(
  "/jobs/pending/acknowledgement/reject",
  async (payload, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/account/bank-action/reject-job";
      if (appType === AppType.cs)
        url = "/corporate/account/banker/bank-action/reject-job";

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const confirmJob = createAsyncThunk(
  "/jobs/pending/acknowledgement/confirm",
  async (payload, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/account/bank-action/confirm-job";
      if (appType === AppType.cs)
        url = "/corporate/account/banker/bank-action/confirm-job";

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const fetchVendors = createAsyncThunk(
  "/vendors",
  async (searchTerm, thunkAPI) => {
    if (searchTerm) thunkAPI.dispatch();
    try {
      return new BACKEND().send({
        type: "get",
        to: `/vendors?searchTerm=${searchTerm || ""}`,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const markForAddressVerification = createAsyncThunk(
  "/jobs/account/vendor-action/added-info",
  async ({ status, accountId }, thunkAPI) => {
    Alert({
      type: "info",
      message: "Sending request...",
    });
    try {
      return new BACKEND().send({
        type: "post",
        to: `/account/vendor-action/added-info?action=${status?.toUpperCase()}&accountId=${accountId}`,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const setCopiedData = createAction("jobs/setCopiedData");

let role =
  getRole() === Roles.bank
    ? "banker"
    : getRole() === Roles.superAdmin
    ? Roles.admin
    : getRole();
const {
    pendingAcknowledgment,
    rejectedJobs,
    closedJobs,
    awaitingBranchConfirmation,
    awaitingVendorAssignment,
    pendingJobs,
    awaitingPayments,
    // paidJobs,
  } = config.routes,
  bankerPages = [
    pendingAcknowledgment,
    rejectedJobs,
    closedJobs,
    awaitingBranchConfirmation,
    awaitingVendorAssignment,
    pendingJobs,
    // paidJobs,
    awaitingPayments,
  ];

export const fetchData = createAsyncThunk(
  `/jobs/${camelCasedPathName()}`,
  async (_, thunkAPI) => {
    try {
      const { pagination } = thunkAPI?.getState()?.jobs,
        { appType } = thunkAPI?.getState()?.auth;

      const pathname = document.location.pathname;
      if (
        bankerPages.includes(pathname) &&
        [Roles.admin, Roles.bank, Roles.superAdmin].includes(getRole())
      )
        role = "banker";
      // else role = role;
      else role = getRole() === Roles.superAdmin ? "admin" : getRole();

      let url = `/account/${role}${pathname}`,
        urlQuery = `/?pageNumber=${pagination?.page}&pageSize=${
          pagination?.pageSize
        }&searchTerm=${pagination?.search || ""}`;

      if (appType === AppType.cs) url = "/corporate" + url;
      console.log(appType);

      if (
        bankerPages.includes(pathname) &&
        [Roles.admin, Roles.bank, Roles.superAdmin].includes(getRole())
      )
        url = `${url}/${getId().user}`;

      url += urlQuery;

      if (
        ["/reports", awaitingVendorAssignment].includes(pathname) &&
        (pagination.vendorId ||
          pagination.stateId ||
          pagination.status ||
          pagination.queue ||
          (pagination.startDate && pagination.endDate))
      ) {
        url =
          url +
          `&stateId=${pagination.stateId}&vendorId=${pagination.vendorId}&status=${pagination.status}&queue=${pagination.queue}&startDate=${pagination.startDate}&endDate=${pagination.endDate}`;
      } else if (pathname === "/pending-jobs") {
        url = url + `&stateId=${pagination.stateId}`;
      }

      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const makePayment = createAsyncThunk(
  "/jobs/awaiting-payment/makePayment",
  async ({ isBulk, vendorId, medium, ...rest }, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = "/payment-log/make-bulk-payment";

      console.log(appType, config.appType === AppType.cs);

      let payload = null;

      if (vendorId) {
        url = "/payment-log/make-bulk-payment";
        payload = { medium: "VENDOR", vendorIds: [vendorId] };
      }
      if (medium) {
        url = "/payment-log/make-bulk-payment";
        payload = { medium, ...rest };
      }

      if (isBulk) {
        url = "/payment-log/make-payment?isBulkPayment=" + isBulk;
      }

      if (appType === AppType.cs) {
        url = "/corporate" + url;
      }

      return new BACKEND().send({
        type: isBulk ? "get" : "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const fetchSummary = createAsyncThunk(
  "/jobs/awaiting-payment/summary",
  async (_, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth,
        userId = getId().user;
      let url = `/account/${role}/jobs/awaiting-payment/summary/${userId}`;

      if (appType === AppType.cs) {
        url = `/corporate/account/${role}/awaiting-payment/summary/${userId}`;
      }

      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const moveJobManually = createAsyncThunk(
  "/jobs/moveJob",
  async (payload, thunkAPI) => {
    try {
      const { appType } = thunkAPI?.getState()?.auth;
      let url = `/account/banker/manually-move-jobs`;

      if (appType === AppType.cs) url = `/corporate` + url;

      return new BACKEND().send({
        type: "post",
        to: url,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const getJobsCost = createAsyncThunk(
  "/jobs/awaiting-payment/getJobsCost",
  async (_, thunkAPI) => {
    try {
      const url = `/account/admin/cost/amount-per-job`;

      return new BACKEND().send({
        type: "get",
        to: url,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

const initialState = {
  [camelCasedPathName()]: [],
  newAddressPayload: {
    newAddress: "",
    accountId: "",
  },
  currentJob: {},
  vendors: [],
  copiedData: "",
  printMod: {
    enable: false,
    ref: null,
    pageTitle: "",
  },
  downloadUrl: config.backendUrl.endsWith("/")
    ? config.backendUrl + ``
    : config.backendUrl + `/`,
  loading: false,
  modalLoading: false,
  searchTerm: "",
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
    status: "",
    vendorId: "",
    stateId: "",
    startDate: "",
    endDate: "",
    queue: "",
  },
  downloadId:
    getRole?.() === Roles.bank
      ? JSON.parse(Storage.get(config.authProps[1]))?.stateId
      : getRole?.() === Roles.vendor
      ? JSON.parse(Storage.get(config.authProps[1]))?.vendorId
      : null,
  userId:
    Storage.get(config.authProps[1]) &&
    JSON.parse(Storage.get(config.authProps[1]))?.userId,
  vendorId:
    Storage.get(config.authProps[1]) &&
    JSON.parse(Storage.get(config.authProps[1]))?.vendorId,
  modal: {
    open: false,
    close: false,
    reassign: false,
    loader: {
      rejectReassign: false,
      reject: false,
      confirm: false,
      closedJobs: false,
    },
  },
  vendorFormPayload: {
    comment: "",
    landmark: "No",
    addressDescription: "",
    accountId: 0,
    photos: [],
    solicitorReport: "",
    status: "",
  },
  rejectJobPayload: {
    accountId: 0,
    bankUserId: "",
    comment: "",
    bankRejectionImages: [],
  },
  rejectAndReassignPayload: {
    accountId: 0,
    newVendorId: "",
    bankUserId: "",
    comment: "",
    updatedAddress: "",
    bankRejectionImages: [],
  },
  downloadData: [],
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setRejectAndReassignPayload: (state, { payload }) => {
      state.rejectAndReassignPayload = {
        ...state.rejectAndReassignPayload,
        ...payload,
      };
    },
    setPrintMod: (state, { payload }) => {
      state.printMod = {
        ...state.printMod,
        ...payload,
      };
    },
    setPagination: (state, { payload }) => {
      state.pagination = {
        ...state.pagination,
        ...payload,
      };
    },
    openModal: (state, { payload }) => {
      state.modal[payload || "open"] = true;
    },
    closeModal: (state, { payload }) => {
      state.modal[payload || "open"] = false;
    },
    setCurrentJob: (state, { payload }) => {
      state.currentJob = payload;
    },
    setSearch: (state, { payload }) => {
      state.searchTerm = payload;
    },
    setCopiedData: (state, { payload }) => {
      state.copiedData = payload;
    },
    getDownloadData: (state, { payload }) => {
      const pathname = document.location.pathname;
      if (
        bankerPages.includes(pathname) &&
        [Roles.admin, Roles.bank, Roles.superAdmin].includes(getRole())
      )
        role = "banker";
      else role = getRole();

      const { pagination, downloadId, downloadUrl } = state,
        paginationQuery = `?searchTerm=${pagination?.search || ""}`;

      state.downloadData = downloadId
        ? ["pdf", "excel"].map((a, b) => ({
            name: (b === 0 ? a : "csv")?.toUpperCase(),
            url:
              downloadUrl +
              `${payload.type}account/${role}${payload.url}/download/${a}/${
                pathname === "/reports"
                  ? ""
                  : [Roles.admin, Roles.bank, Roles.superAdmin].includes(
                      getRole()
                    )
                  ? getId().user
                  : downloadId
              }${paginationQuery}`,
          }))
        : ["pdf", "excel"].map((a, b) => ({
            name: (b === 0 ? a : "csv")?.toUpperCase(),
            url:
              downloadUrl +
              `${payload.type}account/${role}${payload.url}/download/${a}/${
                ["/reports", "/paid-jobs"].includes(pathname)
                  ? ""
                  : [Roles.admin, Roles.bank, Roles.superAdmin].includes(
                      getRole()
                    )
                  ? getId().user
                  : downloadId
              }${paginationQuery}`,
          }));
    },
  },
  extraReducers: (builder) => {
    /** Fetch Vendors Job Builder **/
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(fetchVendors.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state.vendors = payload?.data;
        }
      })
      .addCase(fetchVendors.rejected, (state) => {
        state.modalLoading = false;
      });
    /** Fetch Vendors Job Builder |END| **/

    /** Rejected Job Builder **/
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state[camelCasedPathName()] = payload?.data;
        }
      })
      .addCase(fetchData.rejected, (state) => {
        state.loading = false;
      });
    /** Rejected Job Builder |END| **/

    /** updateVendorsForm Builder **/
    builder
      .addCase(updateVendorsForm.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(updateVendorsForm.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
      })
      .addCase(updateVendorsForm.rejected, (state) => {
        state.modalLoading = false;
      });

    /** rejectAndReassign Builder **/
    builder
      .addCase(rejectAndReassign.pending, (state) => {
        state.modal.loader.rejectReassign = true;
      })
      .addCase(rejectAndReassign.fulfilled, (state, { payload }) => {
        state.modal.loader.rejectReassign = false;
      })
      .addCase(rejectAndReassign.rejected, (state) => {
        state.modal.loader.rejectReassign = false;
      });
    /** getJobsCost Builder **/
    builder
      .addCase(getJobsCost.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobsCost.fulfilled, (state, { payload }) => {
        state.loading = false;

        // @Todo Get cost of each jobs on awaiting payment page for individual jobs
        state.currentJob = payload;
      })
      .addCase(getJobsCost.rejected, (state) => {
        state.loading = false;
      });

    /** closeJob Builder **/
    builder
      .addCase(closeJob.pending, (state) => {
        state.modal.loader.closedJobs = true;
      })
      .addCase(closeJob.fulfilled, (state, { payload }) => {
        state.modal.loader.closedJobs = false;
      })
      .addCase(closeJob.rejected, (state) => {
        state.modal.loader.closedJobs = false;
      });

    /** rejectJob Builder **/
    builder
      .addCase(rejectJob.pending, (state) => {
        state.modal.loader.reject = true;
      })
      .addCase(rejectJob.fulfilled, (state, { payload }) => {
        state.modal.loader.reject = false;
      })
      .addCase(rejectJob.rejected, (state) => {
        state.modal.loader.reject = false;
      });
    /** confirmJob Builder **/
    builder
      .addCase(confirmJob.pending, (state) => {
        state.modal.loader.confirm = true;
      })
      .addCase(confirmJob.fulfilled, (state, { payload }) => {
        state.modal.loader.confirm = false;
      })
      .addCase(confirmJob.rejected, (state) => {
        state.modal.loader.confirm = false;
      });

    /** markForAddressVerification Builder **/
    builder
      .addCase(markForAddressVerification.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(markForAddressVerification.fulfilled, (state, { payload }) => {
        if (payload?.success)
          Alert({
            type: "success",
            message: payload.message,
          });
        else
          Alert({
            type: "error",
            message: payload?.message,
          });
        state.modalLoading = false;
      })
      .addCase(markForAddressVerification.rejected, (state) => {
        state.modalLoading = false;
      });

    /** initUpdateAddress Builder  **/
    builder
      .addCase(initUpdateAddress.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(initUpdateAddress.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
      })
      .addCase(initUpdateAddress.rejected, (state) => {
        state.modalLoading = false;
      });

    /** initUpdateAddress Builder  **/
    builder
      .addCase(fetchSummary.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(fetchSummary.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
        if (payload?.totalAmount) {
          state.currentJob = payload;
        }
      })
      .addCase(fetchSummary.rejected, (state) => {
        state.modalLoading = false;
      });

    /** initUpdateAddress Builder  **/
    builder
      .addCase(makePayment.pending, (state) => {
        state.modal.loader.confirm = true;
      })
      .addCase(makePayment.fulfilled, (state, { payload }) => {
        state.modal.loader.confirm = false;
        if (payload?.totalAmount) {
          // state.currentJob = payload
        }
      })
      .addCase(makePayment.rejected, (state) => {
        state.modal.loader.confirm = false;
      });

    /** moveJobManually Builder  **/
    builder
      .addCase(moveJobManually.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(moveJobManually.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
      })
      .addCase(moveJobManually.rejected, (state) => {
        state.modalLoading = false;
      });
  },
});

export const getJobsData = (state) => state.jobs;
export const {
  setPagination,
  openModal,
  closeModal,
  setCurrentJob,
  setPrintMod,
  setSearch,
  getDownloadData,
  setRejectAndReassignPayload,
} = jobSlice.actions;
export default jobSlice.reducer;
