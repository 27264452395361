import React, { useEffect } from "react";
import * as echarts from "echarts";

const AreaChart = ({
  data,
  colors,
  xAxisData,
  showXAxis,
  smooth,
  wrapperClass,
  areaChatClass,
  name,
  id,
}) => {
  useEffect(() => {
    const myChart = echarts.init(document.getElementById(id));

    const option = {
      xAxis: {
        type: "category",
        show: showXAxis,
        boundaryGap: true,
        data: xAxisData || [],
      },
      yAxis: {
        type: "value",
        show: true,
        // boundaryGap: [0, "30%"],
      },
      tooltip: {
        trigger: "axis",
        formatter: "Created At: <b>{b}</b> <br/> Count: <b>{c}</b>",
      },
      series: [
        {
          name: { name },
          type: "line",
          smooth: smooth,
          symbolSize: 7,
          lineStyle: {
            width: 2,
            color: colors?.line || "#009900",
            shadowColor: "rgba(0,0,0,0.3)",
            shadowBlur: 10,
            shadowOffsetY: 5,
          },
          data: data || [],
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: colors?.areaStart || "#EBF2EB",
                  opacity: 0.3,
                },
                { offset: 1, color: colors?.areaEnd || "#F6F9F6", opacity: 0 },
              ]),
            },
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [data, colors, xAxisData, showXAxis, smooth, name, id]);

  return (
    <div className={` ${wrapperClass}`}>
      <div
        className={` ${areaChatClass}`}
        id={id}
        style={{ height: "400px" }}
      ></div>
    </div>
  );
};

export default AreaChart;
