import Brand from '../assets/images/brand.svg'
import BankUser from '../assets/images/bank-user.png'
import AwaitJ from '../assets/images/awaitJ.svg'
import Acknowledge from '../assets/images/pAcknow.svg'
import pendingJobs from '../assets/images/pJobs.svg'
import power from '../assets/images/power.svg'
import Reject_closeJobs from '../assets/images/r_cJobs.svg'
import Settings from '../assets/images/settings.svg'
import Divider from '../assets/images/Divider.svg'
import PrintRed from '../assets/images/print.svg'
import PrintWhite from '../assets/images/print2.svg'
import { ReactComponent as ArrowUp } from '../assets/images/arrow-up.svg'
import { ReactComponent as ArrowDown } from '../assets/images/arrow-down.svg'
import Landmark from '../assets/images/landmark.svg'
import Download from '../assets/images/download_icon.svg'
import EmptyState from '../assets/images/EmptyState.svg'
import Export from '../assets/images/export.svg'
import Uparrowalt from '../assets/images/uparrowalt.svg'
import Copy from '../assets/images/copy.svg'
import DatePicker from '../assets/images/datePicker.svg'
import BgImage from '../assets/images/conference.jpeg'




export const IBrand = Brand;
export const IBankUser = BankUser;
export const IAwaitJ = AwaitJ;
export const IAcknowledge = Acknowledge;
export const IpendingJobs = pendingJobs;
export const Ipower = power;
export const IReject_closeJobs = Reject_closeJobs;
export const ISettings = Settings;
export const IDivider = Divider;
export const IPrintRed = PrintRed;
export const IPrintWhite = PrintWhite;
export const IArrowUp = ArrowUp;
export const IArrowDown = ArrowDown;
export const ILandmark = Landmark;
export const IDownload = Download; 
export const IEmptyState = EmptyState; 
export const IExport = Export;
export const IUparrowalt = Uparrowalt; 
export const ICopy = Copy; 
export const IDatePicker = DatePicker; 
export const IBgImage = BgImage; 