import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EmailIcon from "@mui/icons-material/Email";
import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "../../utils/alert";
import { IBankUser } from "../../utils/icons.utils";
import { userForgotPassword } from "../../utils/validators";

import OtpInput from "../../components/others/OtpInput";
import {
  forgotPassword,
  getAuthData,
  verifyForgotPassword,
} from "../../features/auth/authSlice";
import config from "../../utils/config";
import Storage from "../../utils/storage";

const ForgotPassword = () => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { loading } = useSelector(getAuthData),
    [showOtp, setShowOtp] = useState(false);

  const formik = useFormik({
    initialValues: { email: "", token: "" },
    validationSchema: userForgotPassword,
    onSubmit: async ({ email }) => {
      try {
        const res = await dispatch(forgotPassword({ email })).unwrap();
        res?.success && setShowOtp(res?.success);
      } catch (err) {
        console.error(err);
        Alert({ type: "error", message: "Something went wrong" });
      }
    },
  });

  return (
    <div className="px-5 py-5">
      <Box className="h-full ">
        <Box className="md:flex md:justify-between h-full">
          <div className="w-full  rounded-l-[20px] rounded-r-[20px] border-l md:border-r-0 border-r lg:p-16 md:p-8 ">
            <img alt="brand" src="/logo.svg" className="my-5 ml-[13%]" />
            <img
              src={IBankUser}
              alt="IBankUser"
              className="w-[73%] m-auto rounded-[24px]"
            />
          </div>
          <div className="flex flex-col justify-center w-full ">
            <div className="md:bg-[var(--home-bg)] xl:p-[15%] lg:p-14 md:p-5 w-full lg:py-[14%] md:py-16 h-full flex flex-col md:justify-center rounded-r-[20px]">
              {showOtp ? (
                <div className="flex flex-col justify-center w-full">
                  <Box className="text-[#201600] flex">
                    <span
                      className="flex cursor-pointer items-center"
                      onClick={(_) => {
                        formik.setValues({ ...formik.values, token: "" });
                        setShowOtp(false);
                      }}
                    >
                      <ArrowBackIosNewIcon color="#201600" fontSize="small" />
                      <Typography className="text-[#201600] text-[16px] ">
                        Back
                      </Typography>
                    </span>
                  </Box>
                  <Box className="text-[#201600] ">
                    <div className="mt-5 mb-20">
                      <Typography className="text-[400] text-[16px] text-[#52525B]">
                        Enter the Token sent to your mailbox to continue.
                      </Typography>
                    </div>
                    <OtpInput
                      {...{
                        value: formik.values.token,
                        length: 8,
                        disabled: loading,
                        onSubmit: async (verificationCode) => {
                          const res = await dispatch(
                            verifyForgotPassword({
                              verificationCode,
                              email: formik.values.email,
                            })
                          ).unwrap();
                          Storage.set(config.authProps[0], res?.data?.token);
                          res?.success &&
                            navigate(config.routes.changePassword, {
                              state: true,
                            });
                        },
                      }}
                    />
                  </Box>
                </div>
              ) : (
                <Box
                  component="form"
                  marginTop=""
                  noValidate
                  onSubmit={formik.handleSubmit}
                  className="bg-white  lg:px-[10%] py-8 md:px-4  md:rounded-[24px]"
                >
                  <h1 className="mb-5 text-[30px] font-bold">
                    Forgot Password
                  </h1>
                  <Typography className="text-[400] text-[16px] text-[#52525B]">
                    Enter your email address to proceed.
                  </Typography>

                  <div className="mt-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[16px] font-medium  text-[#090914]"
                    >
                      Email Address
                    </label>
                    <TextField
                      value={formik.values.email}
                      type="email"
                      name="email"
                      id="email"
                      sx={{ ".MuiInputBase-root": { borderRadius: "10px" } }}
                      className="block w-full bg-[#F8FAFC]  border-0 rounded-[10px] py-4 pl-5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-3 sm:text-sm sm:leading-6"
                      placeholder="E.g johndoe@hotmail.com"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onFocus={formik.handleFocus}
                      error={formik.errors.email && formik.touched.email}
                      helperText={
                        formik.errors.email && formik.touched.email
                          ? formik.errors.email
                          : ""
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <LoadingButton
                    variant="text"
                    color="error"
                    {...{
                      loading,
                      sx: {
                        textTransform: "capitalize",
                        mt: "2.5rem",
                        px: "2.5rem",
                        bgcolor: "#F8FAFC",
                        border: "1px solid rgba(233, 231, 231, 0.7)",
                        borderRadius: "10px",
                        py: "0.75rem",
                        fontSize: "18px",
                        "&:hover": { bgcolor: "#D70900" },
                      },
                    }}
                    type="submit"
                    // root={{backgroundColor: 'red'}}
                    className="rounded-[10px] md:py-3 py-3  font-semibold text-[#D70900]  hover:text-[#FFFFFF]"

                    // className="rounded-[10px] bg-[#] px-10  md:py-3 py-3 mt-10 text-[18px] "
                  >
                    Submit
                  </LoadingButton>
                </Box>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ForgotPassword;
