import { useTheme } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, useMediaQuery } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
 return <Slide direction="up" ref={ref} {...props} />;
});

const CustomModal = ({children, open, close, title}) => {
 const dispatch = useDispatch()
 const theme = useTheme();
 const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

 return (
  <div>
   <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={_=> dispatch(close())}
          aria-labelledby="responsive-dialog-title"
          className="p-10"
          fullWidth
          TransitionComponent={Transition}
        >
          <DialogActions
          className='w-full'
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogTitle style={{fontSize: '26px', fontWeight: 'bold'}}> {title}
            </DialogTitle>
            <Button color="error" 
            onClick={_=> dispatch(close())}
            autoFocus
            >
              <Close />
            </Button>
          </DialogActions>
          
          <DialogContent>
           {children}
          </DialogContent>
        </Dialog>
  </div>
 );
}

export default CustomModal;
