/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import links from "../../utils/linkTree";
import {
  getAuthData,
  getUserById,
  logout,
  setPreloader,
} from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import TabComponent from "../../components/others/tabCom";
import { Roles, getRole } from "../../utils/helper";
import { useEffect } from "react";
import NavMenu from "../others/NavMenu";
import Logout from "../modals/Logout";
import Preloader from "../others/Preloader";
import { useLocation } from "react-router-dom";
import config from "../../utils/config";

const drawerWidth = 340;

export default function HomeSidebar(props) {
  let { navigation } = props;
  navigation = navigation === undefined ? true : false;
  const { children } = props,
    [logouts, setLogout] = React.useState(false),
    dispatch = useDispatch(),
    { pathname } = useLocation(),
    { user, preloading } = useSelector(getAuthData);

  useEffect(() => {
    !user && dispatch(getUserById());
  }, []);

  useEffect(() => {
    setTimeout((_) => {
      dispatch(setPreloader());
    }, 2000);
  }, [preloading]);

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "#fdfcfc",
          color: "#000",
          boxShadow: "none",
          width: "100%",
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Box className=" m-auto  w-full">
            <Box className="flex justify-between">
              <div className="">
                <img src="/logo.svg" alt="brand" className="w-[90%] h-[90%]" />
              </div>
              {pathname !== config.routes.dashboard && props.handleSearch && (
                <div className="relative mt-2 rounded-md w-[35%] hidden md:block">
                  <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3 mb-1">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-[#535763]"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={props.value}
                    onChange={props.handleSearch}
                    className="block w-full bg-[#F9F9F9] rounded-[5px] border-0  py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-[#EFEFF2] placeholder:text-[#535763] focus:ring-1 focus:ring-inset focus:ring-[#e8e8ec] sm:text-sm sm:leading-6"
                    placeholder={
                      props.placeholder ||
                      (getRole() === Roles.vendor
                        ? "Search by account name..."
                        : "Search by account number...")
                    }
                  />
                </div>
              )}
              <NavMenu
                {...{
                  user,
                  role:
                    getRole() === Roles.bank
                      ? "Banker"
                      : getRole() === Roles.superAdmin
                      ? "Super Admin"
                      : getRole(),
                  handleLogout: (_) => setLogout(true),
                }}
              />
            </Box>
          </Box>
        </Toolbar>
        {navigation && (
          <TabComponent onClick={(_) => setLogout(true)} {...{ links }} />
        )}
      </AppBar>

      <Box component="main" className="px-7 sm:mt-32 mt-20">
        <br />
        <br />

        {children}
      </Box>

      <Logout {...{ logouts, setLogout, logout }} />
      <Preloader {...{ preloading }} />
    </Box>
  );
}
