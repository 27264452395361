import React from "react";

const CardComp = ({ title, amount, rate, loading }) => {
  return (
    <div className="card mb-6 md:mb-0 px-5 py-4 bg-white rounded-[22px] border border-[#EFF0F6] ">
      <h2 className="card-title text-[17px] font-[600] leading-[19px] tracking-[1.078px]">
        {title}
      </h2>
      <div className="card-content flex justify-between mt-3">
        <h2 className="text-[22px] font-[600] leading-[34px]">
          {loading ? "..." : amount}
        </h2>
        {/* <h5 style={{ color: rateColor }} className='text-[16px] mt-4 font-[500] leading-[22px]'>{rateSymbol} {Math.abs(rate)}% <RateIcon className="inline-block " /></h5> */}
      </div>
    </div>
  );
};

export default CardComp;
