import {
 createSlice,
 createAsyncThunk,
 createAction
} from "@reduxjs/toolkit";
import config from "../../utils/config";
import BACKEND from "../../utils/backend";
import { AppType } from "../../utils/helper";

export const fetchManualReassignment = createAsyncThunk('/jobs/manualReassignment', async (_, thunkAPI) => {
 try {
  const state = thunkAPI?.getState()?.manualReassignment,
  {appType } = thunkAPI?.getState()?.auth,
  
   query = `?pageNumber=${state?.pagination?.page}&pageSize=${state?.pagination?.pageSize}&searchTerm=${state?.pagination?.search || ''}`;
  let url = "/account/jobs/for-manual-reassignment"+query
  if(appType === AppType.cs) url = '/corporate/account/admin/manual-reassignment'+query; 
  
  console.log(appType);

   return new BACKEND().send({
     type: 'get',
     to: url,
     useAlert: false,
   })
   
 } catch (error) {
   thunkAPI.rejectWithValue("An error occurred somewhere")
 }
})

export const manuallyReassignJob = createAsyncThunk('/jobs/manualReassigment/manuallyReassignJob', async (payload, thunkAPI) => {
  const {appType } = thunkAPI?.getState()?.auth;
  let url = "/account/jobs/initiate-manual-reassignment"
  if(appType === AppType.cs) url = '/corporate/account/admin/manual-reassignment/initiate'; 

  try {
    return new BACKEND().send({
      type: 'post',
      to: url,
      useAlert: true,
      payload
    })

  } catch (error) {
    thunkAPI.rejectWithValue("An error occurred somewhere")
  }
})



export const setCopiedData = createAction("branch/confirmation/setCopiedData");


const initialState = {
 manualReassignment: [],
 loading: false,
 modalLoading: false,
 searchTerm: '',
 copiedData: "",
 downloadExcelUrl: config.backendUrl.endsWith('/') ? config.backendUrl + '' : config.backendUrl+ '/',
 downloadPdfUrl: config.backendUrl.endsWith('/') ? config.backendUrl + '' : config.backendUrl+ '/',
 printMod: { enable: false, ref: null, pageTitle: '' },
 modal: {open: false},
 currentJob: {},
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 },
}


export const manualReassignmentSlice = createSlice({
 name: 'jobs',
 initialState,
 reducers: {
  setPrintMod: (state, {payload}) => {
    state.printMod = {...state.printMod, ...payload}
  },
  setPagination: (state, { payload }) => {
    state.pagination = {...state.pagination, ...payload}
   },
   openModal: (state, {payload}) => {
    state.modal[payload || 'open'] = true
   },
   closeModal: (state, {payload}) => {
    state.modal[payload || 'open'] = false
   },
   setCurrentJob: (state, {payload}) => {
    state.currentJob = payload;
   },
   setSearch: (state, {payload}) => {
    state.searchTerm = payload;
   },
   setCopiedData: (state, { payload }) => {
    state.copiedData = payload; 
  },
 },
 extraReducers: (builder) => {
   
   /** fetchManualReassignment Builder **/
   builder.addCase(fetchManualReassignment.pending, (state) => {
       state.loading = true;
     })
     .addCase(fetchManualReassignment.fulfilled, (state, {
       payload
     }) => {
      state.loading = false;
      if(payload?.success){
        state.pagination.total = payload?.pagination?.totalCount;
        state.pagination.length = state.pagination.pageSize * state.pagination.page;
        state.manualReassignment = payload?.data;
      }
     })
     .addCase(fetchManualReassignment.rejected, (state) => {
       state.loading = false;
     });
   
   /** manuallyReassignJob Builder **/
   builder.addCase(manuallyReassignJob.pending, (state) => {
       state.modalLoading = true;
     })
     .addCase(manuallyReassignJob.fulfilled, (state, {
       payload
     }) => {
      state.modalLoading = false;
     })
     .addCase(manuallyReassignJob.rejected, (state) => {
       state.modalLoading = false;
     });

  }})



    export const getManualReassignmentData = state => state.manualReassignment;
export const {
  setPagination,
  openModal,
  closeModal,
  setCurrentJob,
  setPrintMod,
  setSearch
} = manualReassignmentSlice.actions

export default manualReassignmentSlice.reducer;