/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import HomeLayout from "../../layouts/Home";
import { Typography } from "@material-tailwind/react";
import CardComp from "../../components/others/CardComp";
import { JobsColorData } from "../../utils/fakeData";
import PieChart from "../../components/others/PieChart";
import { AppType, getAmount } from "../../utils/helper";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getDashboardData } from "../../features/dashboard/dashboard";
import { getAuthData } from "../../features/auth/authSlice";
import config from "../../utils/config";

const BankDash = () => {
  const { stats, loading } = useSelector(getDashboardData),
    { preloading, appType } = useSelector(getAuthData);

  const data = useMemo(() => {
    const result = [
      {
        title: "Total Registered Vendors",
        amount: getAmount(
          config.appType === AppType.cav
            ? stats?.totalRegisteredVendors || 0
            : stats.totalRegisteredSolicitors || 0
        ),
        rate: "32",
        id: 1,
      },
      {
        title: "Total Awaiting Vendors Assignment",
        amount: getAmount(stats?.totalVendorsAwaitingAssignment || 0),
        rate: "35",
        id: 2,
      },
      {
        title: "Total Count Of Paid Jobs",
        amount: getAmount(stats?.totalCountOfPaidJobs || 0),
        rate: "34",
        id: 3,
      },
      {
        title: "Total Jobs Awaiting Payment",
        amount: getAmount(stats?.totalJobsAwaitingPayment || 0),
        rate: "38",
        id: 4,
      },
      {
        title: "Total Jobs Awaiting Branch Confirmation",
        amount: getAmount(stats?.totalJobsAwaitingBranchConfirmation || 0),
        rate: "33",
        id: 5,
      },
      {
        title: "Total Pending Jobs",
        amount: getAmount(stats?.totalPendingJobs || 0),
        rate: "35",
        id: 6,
      },
    ];
    if (AppType.cs === appType) delete result[result.length - 2];
    return result;
  }, [loading, stats, preloading]);

  const JobsChartData = useMemo(() => {
    return [
      {
        value: stats?.totalClosedJobs || 0,
        name: loading ? "..." : `${stats?.totalClosedJobs || 0} Closed Jobs`,
      },
      {
        value: stats?.totalRejectedJobs || 0,
        name: loading
          ? "..."
          : `${stats?.totalRejectedJobs || 0} Rejected Jobs`,
      },
    ];
  }, [loading, stats, preloading]);

  return (
    <HomeLayout>
      <div className="  mb-8">
        <Typography className="text-[24px] font-[500]">Dashboard</Typography>
        <Typography className="text-[18px] font-[400]">Overview</Typography>
      </div>
      <div className="md:grid xl:grid-cols-5 lg:grid-cols-6 gap-8 mb-8">
        <div className="md:grid xl:grid-cols-3 md:grid-cols-2 xl:col-span-4 lg:col-span-4 gap-6 ">
          {data.map((data) => (
            <CardComp
              loading={loading}
              title={data.title}
              amount={data.amount}
              key={data.id}
              rate={data.rate}
            />
          ))}
        </div>
        <div className="lg:my-0 my-6 lg:w-full xl:h-full lg:h-[60%] xl:w-full xl:my-0 px-5 w-full xl:col-span-1 lg:col-span-2 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
          <h2 className="text-[17px] font-[600] tracking-[0.18px]">
            Total Jobs
          </h2>
          <h5 className="text-[22px] font-[700] mt-4">
            {loading ? "..." : getAmount(stats?.totalJobs || 0)}
          </h5>
          <div className="w-full">
            <PieChart
              id="bankDash"
              data={JobsChartData}
              color={JobsColorData}
              wrapperClass=" md:pl-16 lg:pl-0 xl:pl-0"
              pieChartClass="xl:w-[470px] lg:w-[430px] md:w-[580px] w-full h-[470px] md:h-[270px]  lg:h-[120px] xl:h-[150px]"
            />
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default BankDash;
