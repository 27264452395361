import config from '../utils/config';
import AwaitingBranchConfirmation from './awaitingBranchConfirmation/Index';
import AwaitingPayments from './awaitingPayments/Index';
import AwaitingVendorAssignment from './awaitingVendorAssignment/Index';
import ClosedJobs from './closedJobs/Index';
import Dashboard from './dashboard/Index';
import ManualReassignment from './manualReassignment/Index';
import PaidJobs from './paidJobs/Index';
import PendingAcknowledgment from './pendingAcknowledgment/Index';
import PendingJobs from './pendingJobs/Index';
import RegisteredEmployees from './registeredEmployees/Index';
import RejectedJobs from './rejectedJobs/Index';
import CreateVendor from './vendor/CreateVendor';
import RegisteredVendor from './vendor/Index';
import Reports from './reports/Index';
import CreateEmployee from './registeredEmployees/CreateEmployee';
import VendorJobs from './awaitingPayments/VendorJobs';

 const { routes } = config;
const Pages = [
	{
		path: routes.createVendor,
		element: <CreateVendor />
	},
	{
		path: routes.registeredVendor,
		element: <RegisteredVendor />,
	},
			{
				path: routes.dashboard,
				element: <Dashboard />,
			},
			{
				path: routes.pendingJobs,
				element: <PendingJobs />,
			},
			{
				path: routes.pendingAcknowledgment,
				element: <PendingAcknowledgment />,
			},
			{
				path: routes.rejectedJobs,
				element: <RejectedJobs />,
			},
			{
				path: routes.closedJobs,
				element: <ClosedJobs />,
			},
			{
				path: routes.awaitingBranchConfirmation,
				element: <AwaitingBranchConfirmation />,
			},
			{
				path: routes.awaitingPayments,
				element: <AwaitingPayments />,
			},
			{
				path: routes.awaitingPayments+'/:id',
				element: <VendorJobs />,
			},
			{
				path: routes.awaitingVendorAssignment,
				element: <AwaitingVendorAssignment />,
			},
			{
				path: routes.paidJobs,
				element: <PaidJobs />,
			},
			{
				path: routes.reports,
				element: <Reports />,
			},
			{
				path: routes.registeredEmployees,
				element: <RegisteredEmployees />,
			},
			{
				path: routes.createEmployees,
				element: <CreateEmployee />,
			},
			{
				path: routes.manualReassignment,
				element: <ManualReassignment />,
			},
			
  ]

export default Pages;